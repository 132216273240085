import cn from 'classnames';
import { ReactSVG } from 'react-svg';

import useWindowDimensions from '../hooks/useWindowDimensions';

type Props = {
  onClick?: () => void;
  Img: string;
  size?: { fullscreen: number; phone: number };
};

const KdSelectionButton = ({
  onClick,
  Img,
  size = {
    fullscreen: 1,
    phone: 0.6,
  },
}: Props) => {
  const { isMobile } = useWindowDimensions();
  const boxClassnames = cn(
    'relative flex justify-center items-center sm:h-[147px] sm:w-[147px] w-[85px] h-[85px] border-[3px] border-[#62777E] rounded-lg'
  );

  return (
    <div
      className={boxClassnames}
      onClick={onClick}
    >
      <ReactSVG
        src={Img}
        style={{
          fill: '#f2f2f2',
          transform: `scale(${isMobile ? size.phone : size.fullscreen})`,
        }}
        width={isMobile ? size.phone : size.fullscreen}
        height="auto"
        fontSize="40px"
      />
    </div>
  );
};
export default KdSelectionButton;
