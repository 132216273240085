// @ts-nocheck
import mapEvMarkerIcon from '../../images/EVBayMarker.svg';
import mapEvCityIcon from '../../images/EVKerbMapIcon.svg';
import { SchemaFiles } from '../../types/schema';
import UKNumberPlateWoff from './fonts/UKNumberPlate.woff';
import UKNumberPlateWoff2 from './fonts/UKNumberPlate.woff2';
import titleFontWoff from './fonts/rajdhani-medium-webfont.woff';
import titleFontWoff2 from './fonts/rajdhani-medium-webfont.woff2';
import primaryFontWoff from './fonts/roboto-medium-webfont.woff';
import primaryFontWoff2 from './fonts/roboto-medium-webfont.woff2';
import alertIcon from './images/alert-icon.svg';
import favicon192 from './images/android-chrome-192x192.png';
import favicon256 from './images/android-chrome-256x256.png';
import appleIcon from './images/apple-touch-icon.png';
import backgroundImage from './images/background-image.jpg';
import carNumber from './images/car-number.svg';
import favicon16 from './images/favicon-16x16.png';
import favicon32 from './images/favicon-32x32.png';
import favicon from './images/favicon.ico';
import leafIcon from './images/leaf.svg';
import secondaryLogo from './images/logo-purple.png';
import logoSmall from './images/logo-small.png';
import mapClusterIcon from './images/map-cluster.svg';
import mapMarkerIcon from './images/map-marker.svg';
import mapCityIcon from './images/mapCityIcon.svg';
import msTile from './images/mstile-150x150.png';
import primaryLogo from './images/primaryLogo.png';
import questionIcon from './images/question-icon.svg';
import successIcon from './images/success-icon.svg';

const schemaFiles: SchemaFiles = {
  images: {
    logoSmall,
    primaryLogo,
    secondaryLogo,
    favicon,
    favicon16,
    favicon32,
    favicon192,
    favicon256,
    appleIcon,
    msTile,
    alertIcon,
    questionIcon,
    successIcon,
    mapClusterIcon,
    mapMarkerIcon,
    mapEvMarkerIcon,
    mapCityIcon,
    mapEvCityIcon,
    backgroundImage,
    backgroundImageInDashboard: false,
    backgroundImageInLanding: true,
    backgroundImageInLoading: false,
    carNumber,
    leafIcon,
  },
  fonts: {
    titleFontWoff,
    titleFontWoff2,
    primaryFontWoff,
    primaryFontWoff2,
    UKNumberPlateWoff,
    UKNumberPlateWoff2,
  },
};

export default schemaFiles;
