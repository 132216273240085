import { useSelector } from 'react-redux';

import { RootState } from '../../../store';
import KdButton from '../../KdButton';
import KdButtonGroup from '../../KdButtonGroup';
import MessageModal from '../MessageModal';

const KdModalController = () => {
  const viewStore = useSelector((state: RootState) => state.viewStore);
  const { modalData } = viewStore;

  return (
    modalData?.show && (
      <MessageModal
        description={modalData?.description}
        title={modalData?.title}
        subDescription={modalData?.subDescription}
        show={modalData?.show}
        actions={
          <KdButtonGroup
            isBackButton={false}
            renderButtons={modalData?.buttonsProps.map((e, i) => (
              <KdButton
                key={i}
                {...e}
                apiCalling={e?.apiCalling && modalData?.active}
              >
                {e.title}
              </KdButton>
            ))}
          />
        }
      />
    )
  );
};

export default KdModalController;
