import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useAppDispatch } from '../../../useAppDispatch';
import { getMenuItemIndex } from '../functions/getMenuItemIndex';
import { MenuItemsType } from '../types/menuTypes';
import useActiveMenuItems from './useActiveMenuItems';

interface UseMenuResult {
  handleClick: (value: string) => void;
  menuItems: MenuItemsType;
}

const useMenu = (): UseMenuResult => {
  const { menuItems } = useActiveMenuItems();

  const dispatch = useAppDispatch();
  const history = useHistory();

  const handleGetMenuIndex = useCallback(
    (href: string) => {
      const menuIndex = getMenuItemIndex(menuItems, href);

      dispatch({
        type: 'SET_SELECTED_HIGHLIGHT',
        payload: { upper: menuIndex, lower: -1 },
      });
    },
    [menuItems, dispatch]
  );

  const handleClick = (href: string) => {
    handleGetMenuIndex(href);

    setTimeout(() => {
      history.push(href);
    }, 500);
  };

  useEffect(() => {
    handleGetMenuIndex(window.location.href);
  }, [handleGetMenuIndex]);

  useEffect(() => {
    dispatch({
      type: 'SET_SUB_ROUTE_PROGRESS',
      payload: 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { handleClick, menuItems };
};

export default useMenu;
