import { BulkUploadProgressStatusEnum } from '../types/enums';
import { SchemaColours } from '../types/schema';

const colours: SchemaColours = require(`../theme/${process.env.REACT_APP_THEME}/schemaColours.json`);

const initColor = colours['secondary'];
const primaryColor = colours['primary'];
const errorColor = colours['warning'];
const successColor = colours['success'];

export const getProgressColor = (progressState: string, failedRows: number) => {
  switch (progressState) {
    case BulkUploadProgressStatusEnum.UPLOADING:
      return primaryColor;
    case BulkUploadProgressStatusEnum.COMPLETE:
      return successColor;
    case BulkUploadProgressStatusEnum.COMPLETE_PARTIALLY:
      return failedRows > 0 ? errorColor : successColor;
    case BulkUploadProgressStatusEnum.STOPPED_DATA:
    case BulkUploadProgressStatusEnum.STOPPED_SERVER:
      return errorColor;
    default:
      return initColor;
  }
};

export const getFileNameColor = (progressState: string, failedRows: number) => {
  switch (progressState) {
    case BulkUploadProgressStatusEnum.UPLOADING:
      return {
        text: 'text-primary',
        icon: 'fill-primary',
      };
    case BulkUploadProgressStatusEnum.COMPLETE:
      return {
        text: 'text-success',
        icon: 'fill-success',
      };
    case BulkUploadProgressStatusEnum.COMPLETE_PARTIALLY:
      return {
        text: failedRows > 0 ? 'text-secondary' : 'text-success',
        icon: failedRows > 0 ? 'fill-secondary' : 'fill-success',
      };
    default:
      return {
        text: 'text-secondary',
        icon: 'fill-secondary',
      };
  }
};
