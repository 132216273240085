import { t } from 'i18next';

import BulkUploadErrorStatus from './BulkUploadErrorStatus';

type Props = {
  progressState: string;
  failedRows: number;
  tables: React.ReactNode;
};

const BulkUploadErros = ({ progressState, failedRows, tables }: Props) => {
  return (
    <>
      {((progressState === 'COMPLETE_PARTIALLY' && failedRows === 0) ||
        progressState === 'COMPLETE') && (
        <p className="text-[#D8D8D8] text-[20px] mb-[20px]">
          {t(
            'Your CSV file has been successfully uploaded. You should now be able to manage your new vehicle.'
          )}
        </p>
      )}
      {progressState === 'COMPLETE_PARTIALLY' && failedRows > 0 && (
        <BulkUploadErrorStatus
          status={`${failedRows} ${
            failedRows > 1 ? t('Errors Found') : t('Error Found')
          }`}
          text={t(
            'There has been a problem whilst uploading your file. Please view and fix the errors in the CSV file before trying again.'
          )}
        />
      )}
      {progressState === 'STOPPED_SERVER' && (
        <BulkUploadErrorStatus
          status={t('Error')}
          text={t('Uh oh! Somethings gone wrong on our end. Please try again.')}
        />
      )}
      {progressState === 'STOPPED_DATA' && (
        <>
          <BulkUploadErrorStatus
            className="mb-[20px]"
            status={t('Incorrect File Format')}
            text={t(
              'Please make sure that your file matches the format shown in the example below and try again.'
            )}
          />
          {tables}
        </>
      )}
    </>
  );
};
export default BulkUploadErros;
