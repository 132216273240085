import { useMemo } from 'react';

import { useAuth } from '../../../hooks/auth/useAuth';
import menuItemsCF from '../data/menuItemsCF';
import menuItemsLA from '../data/menuItemsLA';
import { getActiveMenuItems } from '../functions/getFilteredMenuItems';
import { MenuItemsType } from '../types/menuTypes';

interface UseActiveMenuItemsResult {
  menuItems: MenuItemsType;
}

const useActiveMenuItems = (): UseActiveMenuItemsResult => {
  const { userType } = useAuth();

  const initialMenuItems = userType === 'CF' ? menuItemsCF : menuItemsLA;

  const menuItems = useMemo(
    () => getActiveMenuItems(initialMenuItems),
    [initialMenuItems]
  );

  return { menuItems };
};

export default useActiveMenuItems;
