import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { RootState } from '../store';
import { subRouteDataType } from '../types/subRoutes';
import { useAppDispatch } from '../useAppDispatch';

/**
 *  A function that finds the first entry of subRouteData
 *  that doesn't have bypass flag as an index to be used for subRoute Progress.
 * @param {Integer} subRouteProgress
 * @param {Array} subrouteDataArray
 * @return {integer} Index of subroute that doesn't have bypass flag.
 */
const getPreviousSubRouteIndex = (
  subRouteProgress: number,
  subrouteDataArray: Array<subRouteDataType>
) => {
  if (subRouteProgress === 1) return 0; // return 0 if progress is only 1 deep as we dont need to skip anything.
  // create new array from subrouteDataArray to index of users progress
  const splicedArray = subrouteDataArray.slice(0, subRouteProgress);
  // reverse the array so that we can iterate the correct direction.
  // and find the first subroute that does NOT have bypassPrevious flag.
  const firstPreviousAllowedSubroute = splicedArray
    .reverse()
    .find((subroute) => {
      return !subroute.bypassPrevious;
    });
  // fallback to return 0 if it doesn't find viable route.
  return firstPreviousAllowedSubroute
    ? firstPreviousAllowedSubroute.subRouteIndex
    : 0;
};

const useBackButtonHandler = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const viewStore = useSelector((state: RootState) => state.viewStore);

  const redirectToPreviousPage = () => {
    const { subRouteProgress, subRouteData } = viewStore;
    // check to see if route is within subroute, otherwise use browser history to go back.
    if (subRouteProgress > 0) {
      const newSubRouteIndex = getPreviousSubRouteIndex(
        subRouteProgress,
        subRouteData
      );

      dispatch({
        type: 'SET_SUB_ROUTE_PROGRESS',
        payload: newSubRouteIndex,
      });
    } else {
      history.goBack();
    }
  };

  return { redirectToPreviousPage };
};

export default useBackButtonHandler;
