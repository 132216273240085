import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { RootState } from '../../../store';
import BackButton from '../components/BackButton';
import HeaderContainer from '../components/HeaderContainer';
import HeaderPageTitle from '../components/HeaderPageTitle';
import Logo from '../components/Logo';

type Props = {
  pageTitle: string;
};

const HeaderMobile: React.FC<Props> = ({ pageTitle }) => {
  const viewStore = useSelector((state: RootState) => state.viewStore);
  const history = useHistory();

  const HeaderButton = viewStore.headerButton;

  const ButtonHeader = viewStore.headerButton ? (
    <HeaderButton />
  ) : (
    <BackButton />
  );

  const isDashboard = history?.location?.pathname === '/dashboard';

  return (
    <HeaderContainer
      variant="mobile"
      testId="topbar-mobile"
    >
      {isDashboard ? <Logo /> : <HeaderPageTitle pageTitle={pageTitle} />}
      {!isDashboard && ButtonHeader}
    </HeaderContainer>
  );
};

export default HeaderMobile;
