import { MenuProps } from '../types/menuTypes';
import HighlightDesktop from './HighlightDesktop';
import MenuItem from './MenuItem';
import MenuItemsList from './MenuItemsList';

const MenuDesktop = ({ menuItems, selectedHighlight, onClick }: MenuProps) => {
  return (
    <nav className="fixed top-[theme(height.header)] w-menu h-[calc(100vh-theme(height.header))] overflow-y-auto z-[2000] bg-secondary py-[40px] px-[15px]">
      <MenuItemsList
        className="nextChild:mt-[34px]"
        menuItems={menuItems}
        renderMenuItems={({ item, index }) => (
          <MenuItem
            className="flex h-[50px] w-[50px]"
            icon={item.img}
            key={`menu-${item.testId}`}
            onClick={() => onClick(item.linkHref)}
            selected={selectedHighlight.upper === index}
            title={item.title}
            testId={`menu-${item.testId}`}
          />
        )}
      />
      <HighlightDesktop selectedHighlight={selectedHighlight} />
    </nav>
  );
};

export default MenuDesktop;
