import { useEffect } from 'react';

type Props = {
  ref: any;
  handleClick: Function;
};

const useHandleClickOutside = ({ ref, handleClick }: Props) => {
  useEffect(() => {
    const handleClickOutside = (ev: any) => {
      if (ref.current && !ref.current.contains(ev.target)) {
        handleClick();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);
};
export default useHandleClickOutside;
