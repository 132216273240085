import cn from 'classnames';

import { LayoutProps } from './types/layoutsTypes';

const ContentLayoutCentered = ({ className, children }: LayoutProps) => {
  const classes = cn('flex flex-col items-center text-white', className);

  return <div className={classes}>{children}</div>;
};

export default ContentLayoutCentered;
