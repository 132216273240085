import cn from 'classnames';
import { t } from 'i18next';

import MenuArrowActive from '../../images/MenuArrowActive';

export interface PagingProps {
  pageNumber: number;
  setPageNumber: (arg0: number) => void;
  totalPages?: number;
  className?: string;
}

const arrowClassName = 'fill-[#FFF]';

const KdPagingArrowPicker = ({
  pageNumber,
  totalPages = 1,
  setPageNumber,
  className,
}: PagingProps) => {
  const handleLeftArrowClick = () => {
    if (pageNumber !== 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const handleRightArrowClick = () => {
    if (pageNumber !== totalPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  const classes = cn(
    'flex items-center justify-center mt-[30px] text-[18px]',
    className
  );

  return (
    <div
      className={classes}
      data-testid="paging-bottom-nav"
    >
      <div
        role="button"
        tabIndex={0}
        onKeyDown={handleLeftArrowClick}
        onClick={handleLeftArrowClick}
        className="w-[24px]"
      >
        {pageNumber > 1 ? (
          <MenuArrowActive className={`${arrowClassName} rotate-180`} />
        ) : (
          <MenuArrowActive
            className={`${arrowClassName} rotate-180 opacity-10`}
          />
        )}
      </div>
      {pageNumber} {t('of')} {totalPages}
      <div
        role="button"
        tabIndex={0}
        onKeyDown={handleRightArrowClick}
        onClick={handleRightArrowClick}
        className="w-[24px]"
      >
        {!(pageNumber === totalPages) ? (
          <MenuArrowActive className={arrowClassName} />
        ) : (
          <MenuArrowActive className={`${arrowClassName} opacity-10`} />
        )}
      </div>
    </div>
  );
};

export default KdPagingArrowPicker;
