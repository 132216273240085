type ClearDataOptionsType = {
  clearLocalStorage?: boolean;
  clearCookies?: boolean;
  clearSessionStorage?: boolean;
  reload?: boolean;
};

export const resetSiteData = ({
  clearLocalStorage = true,
  clearCookies = true,
  clearSessionStorage = true,
  reload = true,
}: ClearDataOptionsType) => {
  if (clearLocalStorage) {
    wipeLocalStorage();
  }
  if (clearCookies) {
    killAllCookies();
  }
  if (clearSessionStorage) {
    sessionStorage.clear();
  }
  if (reload) {
    window.location.reload();
  }
};

const wipeLocalStorage = () => localStorage.clear();

const killAllCookies = () => {
  document.cookie.split(';').forEach((c) => {
    document.cookie = c
      .replace(/^ +/, '')
      .replace(/=.*/, `$=;expires=' ${new Date().toUTCString()};path=/`);
  });
};
