import { Suspense, useState } from 'react';
import { useSelector } from 'react-redux';
import { HashRouter, Route, Switch } from 'react-router-dom';

import ErrorBoundary from '../ErrorBoundary';
import { AuthState } from '../hooks/auth/types/authState';
import { AuthStateProvider } from '../hooks/auth/useAuth';
import useWindowDimensions from '../hooks/useWindowDimensions';
import SuspenseFallbackPrivate from '../pages/SuspenseFallback/SuspenseFallbackPrivate';
import SuspenseFallbackPublic from '../pages/SuspenseFallback/SuspenseFallbackPublic';
import KdMenu from '../shared/Menu/KdMenu';
import TopBar from '../shared/TopBar/TopBar';
import { RootState } from '../store';
import { SchemaColours, SchemaFiles } from '../types/schema';
import PreRoute from './PreRoute';
import PrivateRoute from './PrivateRoute';
import routes from './routes';

const schemaFiles: SchemaFiles =
  require(`./../theme/${process.env.REACT_APP_THEME}/schemaFiles.ts`).default;
const schemaColours: SchemaColours = require(`./../theme/${process.env.REACT_APP_THEME}/schemaColours.json`);

const AppRouter = () => {
  const featureFlagStore = useSelector(
    (state: RootState) => state.featureFlagStore
  );
  const { isMobile } = useWindowDimensions();

  const [authState] = useState<AuthState>({
    isLoggedIn: false,
    userType: 'CF',
    user: null,
    userGroup: '',
    organisation: null,
    uiState: {},
    userDetails: null,
    tempName: null,
    isUserRestoreComplete: false,
    restorePathName: null,
  });

  const currentEnvironment = process.env.REACT_APP_ENVIRONMENT;

  const isNotProduction = currentEnvironment !== 'prod';

  const filteredRoutes = routes.filter((route) => {
    if ('featureFlagId' in route) {
      return featureFlagStore[route.featureFlagId];
    }

    if (route.title === 'Developer Tools') {
      return isNotProduction;
    }
    return true;
  });

  const bgStyle =
    schemaFiles.images.backgroundImage &&
    schemaFiles.images.backgroundImageInDashboard
      ? {
          backgroundImage: `url(${schemaFiles.images.backgroundImage})`,
        }
      : {
          backgroundColor: schemaColours.backgroundColor,
        };

  return (
    <div
      className={`bg-[${schemaColours.backgroundColor}] bg-fixed bg-no-repeat bg-cover bg-center h-[100vh]`}
      style={bgStyle}
    >
      <HashRouter>
        <AuthStateProvider value={authState}>
          <Switch>
            {filteredRoutes.map((route) => {
              return route.isPrivate ? (
                <PrivateRoute
                  key={route.path}
                  exact
                  path={route.path}
                >
                  <PreRoute route={route}>
                    <>
                      {route.topbar && <TopBar pageTitle={route.title} />}
                      {!route.hideMenu && (
                        <KdMenu type={isMobile ? 'mobile' : 'desktop'} />
                      )}
                      <ErrorBoundary>
                        <Suspense fallback={<SuspenseFallbackPrivate />}>
                          <route.component {...route.props} />
                        </Suspense>
                      </ErrorBoundary>
                    </>
                  </PreRoute>
                </PrivateRoute>
              ) : (
                <Route
                  key={route.path}
                  exact
                  path={route.path}
                >
                  <PreRoute route={route}>
                    <Suspense fallback={<SuspenseFallbackPublic />}>
                      <route.component {...route.props} />
                    </Suspense>
                  </PreRoute>
                </Route>
              );
            })}
          </Switch>
        </AuthStateProvider>
      </HashRouter>
    </div>
  );
};

export default AppRouter;
