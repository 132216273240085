import cn from 'classnames';

import { LayoutProps } from '../types/layoutsTypes';

export const MobileContentFooter = ({ children, className }: LayoutProps) => (
  <footer
    className={cn(
      'fixed h-fit left-0 right-0 bottom-[theme(height.mobileMenu)] flex p-[30px]',
      className
    )}
  >
    {children}
  </footer>
);
