import cn from 'classnames';
import { useHistory } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

import { TopbarButtonType } from './HeaderDesktop';

type dropdownItemType = {
  button: TopbarButtonType;
  isSelected: boolean;
  onHandleClick: () => void;
};
const DropdownItem = ({
  button,
  isSelected,
  onHandleClick,
}: dropdownItemType) => {
  const size = button?.size ?? 'normal';
  const history = useHistory();

  const handleClick = () => {
    onHandleClick();
    if (button?.onClick) {
      button.onClick();
    }
    if (button?.path) {
      history.push(button?.path);
    }
  };

  const classnames = cn(
    'w-full flex items-center text-[20px] px-[5px] py-[7px] hover:bg-buttonSecondaryHover focus:bg-buttonSecondaryHover rounded-lg ',
    {
      'bg-buttonSecondaryHover': isSelected,
    }
  );

  const reactSVGclassnames = cn(
    'pr-[10px] ',
    size === 'small ' ? 'user-profile-icon-small' : 'user-profile-icon'
  );

  return (
    <div
      data-testid={button?.title}
      role="button"
      tabIndex={0}
      className={classnames}
      onClick={handleClick}
      onMouseEnter={onHandleClick}
      onMouseLeave={() => {}}
      key={button.title}
    >
      <div className="flex justify-center align-center w-[40px]">
        <ReactSVG
          src={button.icon}
          className={reactSVGclassnames}
        />
      </div>
      <p>{button.title}</p>
    </div>
  );
};
export default DropdownItem;
