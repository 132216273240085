import 'react-day-picker/dist/style.css';
import { createGlobalStyle } from 'styled-components';

import {
  SchemaColours,
  SchemaElements,
  SchemaFiles,
  SchemaFonts,
} from './types/schema';

const schemaFiles: SchemaFiles =
  require(`./theme/${process.env.REACT_APP_THEME}/schemaFiles.ts`).default;
const colours: SchemaColours = require(`./theme/${process.env.REACT_APP_THEME}/schemaColours.json`);
const fonts: SchemaFonts = require(`./theme/${process.env.REACT_APP_THEME}/schemaFonts.json`);
const elements: SchemaElements = require(`./theme/${process.env.REACT_APP_THEME}/schemaElements.json`);

const getThemeVariablesAsString = () => {
  return [
    getVariables(colours),
    getVariables(fonts),
    getVariables(elements),
  ].join('');
};

const getVariables = (obj: any, variablePrefix?: string) => {
  const prefix = variablePrefix || '';

  return Object.entries(obj).reduce(
    (acc, [key, val]) => acc + `--${prefix}${key}: ${val};`,
    ''
  );
};

const GlobalStyle = createGlobalStyle`
:root {
  ${getThemeVariablesAsString()}

   @font-face {
    font-weight: semi-bold;
    font-family: ${fonts.titleFont};
    src: url(${schemaFiles.fonts.titleFontWoff}) format('woff2'),
      url(${schemaFiles.fonts.titleFontWoff2}) format('woff');
  }

  @font-face {
    font-weight: normal;  
    font-family: ${fonts.primaryFont};
    src: url(${schemaFiles.fonts.primaryFontWoff}) format('ttf');
  }

  @font-face {
    font-weight: bold;
    font-family: ${fonts.numberPlateFont};
    src: url(${schemaFiles.fonts.UKNumberPlateWoff2}) format('woff2'),
      url(${schemaFiles.fonts.UKNumberPlateWoff}) format('woff');
  }
}`;

export default GlobalStyle;
