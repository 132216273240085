import { CognitoUser } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import { t } from 'i18next';
import React, {
  ReactNode,
  createContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { callGetUser } from '../../api/kerb';
import { resetSiteData } from '../../functions/resetSiteData';
import { getFirstPartOfEmail } from '../../functions/utils';
import useHandleToast from '../useHandleToast';
import { useRedirect } from '../useRedirect';
import { AwsInstance } from './awsManager';
import { AuthContextType } from './types/authContextType';
import { AuthState } from './types/authState';
import { SignUpData } from './types/signUpData';
import { UiState } from './types/uiState';
import { DbOrganisation, DbUser, UserDetails } from './types/userDetails';

export const AuthContext = createContext<AuthContextType | null>(null);

const awsInstance = AwsInstance.getInstance();
export const AuthStateProvider = ({
  value,
  children,
}: {
  value: AuthState;
  children: ReactNode;
}) => {
  const [state, setState] = useState<AuthState>(value);
  const [aws] = useState<AwsInstance>(awsInstance);
  const { pathname } = useRedirect();
  const [originalPath, setOriginalPath] = useState<string>(null);

  const prevStateRef = useRef<AuthState>();

  useEffect(() => {
    if (state.isUserRestoreComplete && pathname === '/') {
      setState((prevState) => ({ ...prevState, isUserRestoreComplete: false }));
      setTimeout(() => {
        authRedirect();
      }, 100);
    }
    prevStateRef.current = state;
  }, [state]);

  const { handleToast } = useHandleToast();
  const { redirectTo } = useRedirect();

  useEffect(() => {
    const init = async () => {
      setOriginalPath(pathname);
      await aws.setEventHandlers({
        signInHandler,
        signOutHandler,
        restoreHandler,
        restoreErrorHandler,
      });

      const savedUserType = localStorage.getItem('userType') as 'CF' | 'LA';

      setState((prevState) => ({
        ...prevState,
        userType: savedUserType,
        isUserRestoreComplete: false,
      }));
    };
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signInHandler: (challenge: string) => void = async (
    challenge: string
  ) => {
    if (challenge === 'NEW_PASSWORD_REQUIRED') {
      redirectTo('/newpassword');
      return;
    }
    redirectTo('/');
  };

  const signOutHandler: () => void = () => {
    setState((prevState) => {
      prevState.isLoggedIn = false;
      prevState.tempName = null;
      prevState.user = null;
      prevState.userGroup = '';
      prevState.userDetails = null;
      prevState.uiState = null;
      prevState.organisation = null;
      prevState.error = null;
      return prevState;
    });
    redirectTo('/');
  };

  const restoreHandler: () => void = async () => {};

  const restoreErrorHandler: (error: any) => void = (error: any) => {
    redirectTo('/');
  };

  const setUserType = (userType: 'CF' | 'LA') => {
    if (state.userType === userType) return;
    aws.resetByUserType(userType);
    localStorage.setItem('userType', userType);
    setState((prevState) => ({ ...prevState, userType: userType }));
  };

  const setUserDetails = (userDetails: UserDetails) => {
    setState((prevState) => ({ ...prevState, userDetails: userDetails }));
  };

  const setUiState = (uiState: UiState) => {
    localStorage.setItem('uiState', JSON.stringify(uiState));
    if (JSON.stringify(state.uiState) === JSON.stringify(uiState)) return;
    setState((prevState) => ({ ...prevState, uiState: uiState }));
  };

  const setUser = (user: DbUser) => {
    setState((prevState) => ({ ...prevState, user: user }));
  };

  const setOrganisation = (organisation: DbOrganisation) => {
    setState((prevState) => ({ ...prevState, organisation: organisation }));
  };

  const setTempName = (tempName: string) => {
    setState((prevState) => ({ ...prevState, tempName: tempName }));
  };

  const assertLoggedOutUserType = async (expectedUserType: 'CF' | 'LA') => {
    if (state.userType !== expectedUserType) {
      redirectTo('/');
    }
  };

  const assertLoggedInUserType = async (): Promise<void> => {
    console.info('assertLoggedInUserType', state.userType);
    console.info('state.userGroup', state.userGroup);
    if (
      (state.userType === 'CF' && state.userGroup === 'Admin') ||
      (state.userType === 'LA' && state.userGroup === 'LA-Admin')
    ) {
      return;
    }
    signOut();
    redirectTo('/landing');
  };

  const assertUserLogin = () => {
    if (!aws?.isLoggedIn()) {
      redirectTo('/');
    }
  };

  const setError = (error: string) => {
    if (state.error === error) return;
    setState((prevState) => ({ ...prevState, error: error }));
  };

  const getAuth = () => Auth;

  const signIn = async (username: string, password: string) => {
    try {
      const cognitoUser = await getAuth().signIn(username, password);
      const fullName = cognitoUser?.attributes?.name;
      const email = cognitoUser?.attributes?.email ?? username;

      const tempName =
        fullName ?? email
          ? `${getFirstPartOfEmail(email)} ${getFirstPartOfEmail(email)}`
          : '';
      setTempName(tempName);
      // if the AWS  doesn't throw an error and returns a user, then the user challenge should be checked.
      if (cognitoUser?.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setUiState({
          ...state.uiState,
          email: username,
        });
        setTempValue(password);
        signInHandler(cognitoUser.challengeName);
      }
    } catch (error) {
      console.error('Error during sign in:', error);
      handleSignInError(error, username);
    }
  };

  const handleSignInError = async (err: any, username: string) => {
    if (
      err?.code === 'UserNotFoundException' ||
      err?.code === 'NotAuthorizedException'
    ) {
      setError(t('Incorrect username or password'));
      return;
    }

    if (err?.code === 'UserNotConfirmedException') {
      setUiState({ ...state.uiState, email: username });
      redirectTo('/confirm');
      return;
    }

    handleToast({
      content: err?.message ?? err ?? t('Unknown Error'),
      noTopBar: true,
    });
  };

  const completeNewPassword = async (
    email: string,
    oldPassword: string,
    newPassword: string
  ) => {
    const cognitoUser = await getAuth().signIn(email, oldPassword);
    signIn(email, oldPassword)
      .then(() => {
        getAuth()
          .completeNewPassword(cognitoUser, newPassword)
          .catch((err) => {
            handleToast({
              content: err?.message,
              noTopBar: true,
            });
            return;
          });
      })
      .catch((err) => {
        handleToast({
          content: err?.message,
          noTopBar: true,
        });
        return;
      })
      .finally(() => redirectTo('/'));
  };

  const signUp = async (data: SignUpData) => {
    const fullName = data?.firstName + ' ' + data?.lastName;
    await getAuth().signUp({
      username: data?.username.toLowerCase(),
      password: data?.password,
      attributes: {
        name: fullName,
        address: '',
        email: data?.username.toLowerCase(),
      },
      validationData: [],
    });
  };

  const confirmSignup = async (username: string, code: string) => {
    await getAuth().confirmSignUp(username, code, {
      forceAliasCreation: true,
    });
  };

  const resendSignUp = async (username: string) => {
    await getAuth().resendSignUp(username);
  };

  const forgotPassword = async (username: string) => {
    await getAuth().forgotPassword(username);
  };

  const forgotPasswordSubmit = async (
    username: string,
    confirmCode: string,
    newPassword: string
  ) => {
    await getAuth().forgotPasswordSubmit(username, confirmCode, newPassword);
  };

  const signOut = async () => {
    const savedUserType = localStorage.getItem('userType') as 'CF' | 'LA';
    await getAuth()
      ?.signOut({ global: true })
      .catch(() => {
        resetSiteData({});
      })
      .finally(() => {
        setState((prevState) => ({
          isLoggedIn: false,
          userType: savedUserType,
          user: null,
          userGroup: '',
          organisation: null,
          uiState: {},
          userDetails: null,
          tempName: null,
          isUserRestoreComplete: false,
          restorePathName: null,
        }));
        localStorage.setItem('uiState', JSON.stringify({}));
        redirectTo('/');
      });
  };

  const setTempValue = (value: string) => {
    aws.setTempValue(value);
  };
  const getTempValue = (): string => {
    return aws.getTempValue();
  };

  const clearAllCookies = () => {
    document.cookie = '';
  };

  const restoreUserDetails = async (): Promise<Partial<AuthState>> => {
    try {
      await aws.restoreSession();

      console.info('***************************');
      console.info('*** restoreUserDetails  ***');
      console.info('***************************');
      console.info(`Stating Path: ${originalPath}`);

      const cognitoUser = aws?.getCognitoUser();
      const session = aws?.getCognitoSession();

      const userGroup: string =
        (session?.getIdToken()?.payload['cognito:groups'][0] as string) ?? '';
      const savedUiState = JSON.parse(localStorage.getItem('uiState') ?? '{}');

      const uiState: UiState = {
        isResettingPassword: false,
        confirmationCode: null,
        securityCode: null,
        ...savedUiState,
        ...state?.uiState,
      };

      const dbUser: DbUser = await getDatabaseUser(cognitoUser);
      const organisation = dbUser?.organisation;

      const userDetails: UserDetails = dbUser
        ? {
            userId: dbUser.id,
            username: dbUser.id,
            firstName: dbUser.name,
            lastName: dbUser.name,
            name: dbUser.name,
            email: dbUser.contact_email,
            organisation: dbUser?.organisation?.name,
          }
        : null;

      const newPartialState = {
        userGroup: userGroup,
        uiState,
        userDetails,
        user: dbUser,
        organisation,
        isLoggedIn: !!cognitoUser,
        isUserRestoreComplete: true,
        restorePathName: originalPath,
      };
      setState((prevState) => {
        return { ...prevState, ...newPartialState };
      });
      console.info('*******************************');
      console.info('*** END restoreUserDetails  ***');
      console.info('*******************************');
      console.info(`Original Path: ${originalPath}`);
      return newPartialState;
    } catch (e) {
      setState((prevState) => {
        return {
          ...prevState,
          isUserRestoreComplete: true,
          restorePathName: originalPath,
        };
      });
      console.info('*******************************');
      console.info('*** END restoreUserDetails !***');
      console.info('*******************************');
      console.info(`Original Path: ${originalPath}`);
      return null;
    }
  };

  const getDatabaseUser = async (user: CognitoUser): Promise<DbUser> => {
    const userName = user?.getUsername();
    if (!userName) return null;
    const response: Response = await callGetUser(userName).catch((e) => {
      console.error(e);
      return null;
    });
    const result = (await response.json()) as DbUser;
    if (!result.id) return null;
    return result;
  };

  const authRedirect = async () => {
    if (state.isLoggedIn) {
      if (state.organisation) {
        console.info(`User is logged in`);
        setState((prevState) => ({ ...prevState, restorePathName: null }));
        const excludedUrls = ['/login', '/login-la', '/landing', '/'];
        if (
          !excludedUrls.includes(state.restorePathName) &&
          state.restorePathName &&
          pathname !== state.restorePathName
        ) {
          redirectTo(state.restorePathName);
        } else {
          redirectTo('/dashboard');
        }
      } else {
        console.info(`User is logged in, but no organisation was detecetd`);
        redirectTo('/addorganisation');
        handleToast({
          variant: 'success',
          content: 'Please create your organisation',
          noTopBar: true,
          title: 'Successful first login',
        });
      }
    } else {
      if (state.userType === 'CF') {
        redirectTo('/login');
      } else if (state.userType === 'LA') {
        redirectTo('/login-la');
      } else {
        redirectTo('/landing');
      }
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        isLoggedIn: !!aws?.isLoggedIn(),
        restorePathName: state.restorePathName,

        setUserType,
        setUserDetails,
        setUiState,
        setUser,
        setOrganisation,
        assertLoggedInUserType,
        assertLoggedOutUserType,
        assertUserLogin,
        setError,
        setTempValue,
        getTempValue,

        signIn,
        completeNewPassword,
        signUp,
        confirmSignup,
        resendSignUp,
        forgotPassword,
        forgotPasswordSubmit,
        signOut,
        clearAllCookies,
        restoreUserDetails,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => React.useContext(AuthContext);
