import { t } from 'i18next';
import { ReactNode } from 'react';
import { ReactSVG } from 'react-svg';

import DownloadIcon from '../../images/Download.svg';
import { SchemaColours } from '../../types/schema';
import KdRowLabel from './KdRowLabel';
import KdTableCell from './KdTableCell';

const colours: SchemaColours = require(`../../theme/${process.env.REACT_APP_THEME}/schemaColours.json`);

const KdTable = ({
  data,
  href,
  rules = null,
}: {
  data: { [key: string]: Array<string> };
  href: string;
  rules?: { [key: string]: ReactNode };
}) => {
  const headers = Object.keys(data);
  return (
    <div className="flex flex-col">
      <div className="flex flex-row">
        {headers.map((header: string, column) => (
          <div
            className="flex flex-col"
            key={`column${column}`}
          >
            <KdTableCell
              text={header}
              isHeader
              rules={rules ? rules[header] : null}
              rowLabel={column === 0 ? <KdRowLabel text={'Header'} /> : null}
            />
            {data[header].map((c, row) => (
              <KdTableCell
                text={c}
                key={`data:${row}-${column}`}
                rowLabel={
                  column === 0 && <KdRowLabel text={`Entry ${row + 1}`} />
                }
              />
            ))}
          </div>
        ))}
      </div>
      <div className="flex w-full justify-center items-center">
        <a
          href={href}
          rel="noreferrer"
          target="_blank"
          className="flex mt-[10px] mb-[15px]"
        >
          <p className="mr-[5px] text-primary italic text-[16px] underline">
            {t('Download Template') ?? ' Download Template'}
          </p>
          <ReactSVG
            src={DownloadIcon}
            style={{ fill: colours.accentPrimary }}
          />
        </a>
      </div>
    </div>
  );
};
export default KdTable;
