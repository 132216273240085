import i18n, { t } from '../../../i18n';
import analyticsHoverImgCtc from '../../../theme/ctc/images/dashboard/analytics.svg';
import orgHoverImgCtc from '../../../theme/ctc/images/dashboard/org.svg';
import usersHoverImgCtc from '../../../theme/ctc/images/dashboard/users.svg';
import analyticsHoverImgGrid from '../../../theme/grid/images/dashboard/analytics.svg';
import orgHoverImgGrid from '../../../theme/grid/images/dashboard/org.svg';
import usersHoverImgGrid from '../../../theme/grid/images/dashboard/users.svg';
import { MenuItemsType } from '../types/menuTypes';
import { getMenuDashboardIcon, getMenuIcon } from './getMenuIcon';

const theme = process.env.REACT_APP_THEME;

const menuItemsLA: MenuItemsType = [];

const fill = () => {
  const array: MenuItemsType = [
    {
      title: t('Dashboard'),
      description: '',
      img: getMenuIcon('dashboard'),
      linkHref: '/dashboard',
      highlightLocations: ['dashboard'],
      testId: 'home-button',
      hoverImage: null,
      userType: 'LA',
      visibleOnDashboard: false,
    },
    {
      title: t('Analytics'),
      description: t('View the analytics for the loading bays in your area'),
      img: getMenuIcon('analytics'),
      imgDashboard: getMenuDashboardIcon('analytics'),
      linkHref: '/analytics-la',
      highlightLocations: ['analytics-la'],
      testId: 'analytics-button',
      hoverImage:
        theme === 'grid' ? analyticsHoverImgGrid : analyticsHoverImgCtc,
      userType: 'LA',
    },
    {
      title: t('Manage Users'),
      description: t('Manage the users in your organisation and add new users'),
      img: getMenuIcon('users'),
      imgDashboard: getMenuDashboardIcon('users'),
      linkHref: '/manageusers-la',
      highlightLocations: ['manageusers-la', 'add-new-users'],
      testId: 'manage-users-button',
      hoverImage: theme === 'grid' ? usersHoverImgGrid : usersHoverImgCtc,
      userType: 'LA',
    },
    {
      title: t('Edit Organisation'),
      description: t(
        'Edit your organisation, including addresses and contact details'
      ),
      img: getMenuIcon('organisation'),
      linkHref: '/editorganisation-la',
      highlightLocations: ['editorganisation-la'],
      testId: 'edit-organisation-button',
      hoverImage: theme === 'grid' ? orgHoverImgGrid : orgHoverImgCtc,
      userType: 'LA',
      visibleOnDashboard: false,
    },
    {
      title: t('Documents'),
      description: t('View and download documents for your organisation'),
      img: '',
      linkHref: '/documents-la',
      highlightLocations: ['documents-la'],
      testId: 'documents-button',
      hoverImage: theme === 'grid' ? orgHoverImgGrid : orgHoverImgCtc,
      hide: true,
      userType: 'LA',
    },
  ];
  // reset array contents with fresh translated values
  menuItemsLA.length = 0;
  menuItemsLA.push(...array);
};

i18n.on('languageChanged init', () => {
  fill();
});

export default menuItemsLA;
