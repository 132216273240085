import cn from 'classnames';

import { LayoutProps } from '../types/layoutsTypes';

export const MobileContentHeader = ({ children, className }: LayoutProps) => (
  <header
    className={cn(
      'sticky top-0 flex h-fit mb-[30px] short:mb-[20px]',
      className
    )}
  >
    {children}
  </header>
);
