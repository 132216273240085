import { t } from 'i18next';
import { useState } from 'react';

import { UploadToS3, callGetUploadStatus, callGetUploadUrl } from '../api/kerb';
import {
  BulkUploadEntityTypes,
  BulkUploadResponse,
  HTTP_STATUS_COMPLETE,
  HTTP_STATUS_INPROGRESS,
  OVERALL_STATUS_DATAERROR,
  OVERALL_STATUS_SERVERERROR,
  UploadStatus,
} from '../types/bulkUpload';

export type UseBulkUpload = {
  initUpload?: (
    entityType: BulkUploadEntityTypes
  ) => Promise<BulkUploadResponse>;
  startUpload?: (file: File, upload: BulkUploadResponse) => Promise<void>;
  startProgressCheck?: (correlation_id: string) => Promise<void>; // returns true if the upload is complete, false if there was an error
  uploadStatus?: UploadStatus;
  inProgress?: boolean;
  progressState?:
    | 'INIT'
    | 'UPLOADING'
    | 'COMPLETE_PARTIALLY'
    | 'COMPLETE'
    | 'STOPPED_SERVER'
    | 'STOPPED_DATA';
  progress?: number;
  totalRows?: number;
  processedRows?: number;
  failedRows?: number;
  skippedRows?: number;
  createdRows?: number;
};

export const useBulkUpload = (): UseBulkUpload => {
  const [uploadStatus, setUploadStatus] = useState<UploadStatus>(null);
  const [inProgress, setInProgress] = useState(true);
  const [progress, setProgress] = useState<number>(0);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [processedRows, setProcessedRows] = useState<number>(0);
  const [failedRows, setFailedRows] = useState<number>(0);
  const [skippedRows, setSkippedRows] = useState<number>(0);
  const [createdRows, setCreatedRows] = useState<number>(0);
  const [progressState, setProgressState] = useState<
    | 'INIT'
    | 'UPLOADING'
    | 'COMPLETE_PARTIALLY'
    | 'COMPLETE'
    | 'STOPPED_SERVER'
    | 'STOPPED_DATA'
  >('INIT');

  const startProgressCheck = async (correlation_id: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      const interval = setInterval(async () => {
        try {
          const result = await callGetUploadStatus({
            correlation_id,
            page_number: 1,
            page_size: 13,
            row_status: 'FAILED',
          });
          setUploadStatus(result);

          const processedRows = result.body?.row_counts?.processed ?? 0;
          const totalRows = result.body?.row_counts?.total ?? 0;
          const failedRows = result.body?.row_counts?.failed ?? 0;
          const skippedRows = result.body?.row_counts?.skipped ?? 0;
          const createdRows = result.body?.row_counts?.created ?? 0;

          setProcessedRows(processedRows);
          setTotalRows(totalRows);
          setFailedRows(failedRows);
          setSkippedRows(skippedRows);
          setCreatedRows(createdRows);
          setProgress(
            Math.max(Math.round((processedRows / totalRows) * 100), 1)
          );

          if (result.httpStatus === HTTP_STATUS_INPROGRESS) {
            setProgressState('UPLOADING');
          } else if (result.httpStatus === HTTP_STATUS_COMPLETE) {
            setProgress(100);
            clearInterval(interval);
            setInProgress(false);
            if (result.body.status.code === OVERALL_STATUS_DATAERROR) {
              if (result.body.row_counts.processed === 0) {
                setProgressState('STOPPED_DATA');
              } else {
                setProgressState('COMPLETE_PARTIALLY');
              }
            } else if (result.body.status.code === OVERALL_STATUS_SERVERERROR) {
              setProgressState('STOPPED_SERVER');
            } else {
              setProgressState('COMPLETE');
            }
          }
        } catch (error) {
          setProgress(100);
          clearTimeout(interval);
          const options = { cause: JSON.stringify({ error, uploadStatus }) };
          throw new Error(t('Unexpected error'), options);
        }
      }, 1000);
    });
  };

  const initUpload = async (
    entityType: BulkUploadEntityTypes
  ): Promise<BulkUploadResponse> => {
    try {
      return await callGetUploadUrl({ entity_type: entityType });
    } catch (error) {
      throw error;
    }
  };

  const startUpload = async (file: File, upload: BulkUploadResponse) => {
    try {
      const {
        correlation_id,
        presigned_upload_config: { url, fields },
      } = upload;
      if (!file) throw new Error(t('No file selected'));
      if (!url) throw new Error(t('No upload url available'));
      if (!correlation_id)
        throw new Error(t('No upload correlation_id available'));
      UploadToS3(file, url, fields);
    } catch (error) {
      throw error;
    }
  };

  return {
    initUpload,
    startUpload,
    startProgressCheck,
    uploadStatus,
    inProgress,
    progressState,
    progress,
    totalRows,
    processedRows,
    failedRows,

    skippedRows,
    createdRows,
  };
};
