// images (icons) used in landing page buttons
import LandingPageButtonInfo from '../types/landingPageButtonInfo';
import businessImg from './icons/organisation.svg';
import vanImage from './icons/van.svg';

const getLandingPageButtons = (
  t: Function,
  laUserFlow: boolean
): LandingPageButtonInfo[] => [
  {
    title: t('Local Authority'),
    description: t('Open the Local Authority Dashboard'),
    img: businessImg,
    linkHref: '/login-la',
    visible: laUserFlow,
    testId: 'local-authority-button',
    userType: 'LA',
  },
  {
    title: t('Commercial Fleet'),
    description: t('Open the Commercial Fleet Dashboard'),
    img: vanImage,
    linkHref: '/login',
    visible: true,
    testId: 'commercial-fleet-button',
    userType: 'CF',
  },
];

export default getLandingPageButtons;
