import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { getMenuItemWidth } from '../functions/getMenuItemWidth';
import { MenuProps } from '../types/menuTypes';
import HighlightMobile from './HighlightMobile';
import MenuItem from './MenuItem';
import MenuItemsList from './MenuItemsList';
import MenuMoreButton from './MenuMoreButton';

const MenuMobile = ({ menuItems, selectedHighlight, onClick }: MenuProps) => {
  const [isHighlightMore, setIsHighlightMore] = useState(false);

  const { width } = useWindowDimensions();
  const location = useLocation();

  const handleMoreClick = () => {
    setIsHighlightMore(true);
  };

  const itemWidth = getMenuItemWidth(menuItems, width);
  const isAboutPage = location.pathname === '/about';

  const isMoreButtonActive =
    selectedHighlight.upper > 4 || isHighlightMore || isAboutPage;

  return (
    <nav
      data-testid="bottom-navigation"
      className="fixed bottom-0 inset-x-0 h-mobileMenu bg-secondary shadow px-[10px] z-[2500]"
    >
      <MenuItemsList
        className="flex justify-between items-center h-full"
        menuItems={menuItems}
        renderMenuItems={({ item, index }) => (
          <>
            {index < 4 && (
              <MenuItem
                icon={item.img}
                key={`menu-${item.testId}`}
                onClick={() => onClick(item.linkHref)}
                selected={selectedHighlight.upper === index}
                style={{ width: `${itemWidth}px` }}
              />
            )}
          </>
        )}
        render={(menuItems) => (
          <MenuMoreButton
            itemWidth={itemWidth}
            isMoreButtonActive={isMoreButtonActive}
            menuItems={menuItems}
            onClick={handleMoreClick}
          />
        )}
      />
      <HighlightMobile
        itemWidth={itemWidth}
        isMoreButtonActive={isMoreButtonActive}
        selectedIndex={selectedHighlight.upper}
      />
    </nav>
  );
};

export default MenuMobile;
