import cn from 'classnames';

type Props = {
  className?: string;
  status: string;
  text: string;
};

const BulkUploadErrorStatus = ({ className, status, text }: Props) => {
  return (
    <>
      <p className="sm:text-[25px] text-[17px] mb-[20px] font-semibold">
        {status}
      </p>
      <p
        className={cn(
          'sm:text-[20px] #D8D8D8 max-w-[710px] leading-7',
          className
        )}
      >
        {text}
      </p>
    </>
  );
};
export default BulkUploadErrorStatus;
