import React from 'react';
import { useTranslation } from 'react-i18next';

const schemaFiles =
  require(`../theme/${process.env.REACT_APP_THEME}/schemaFiles.ts`).default;

const WarningExclamation = ({ className }: { className?: string }) => {
  const { t } = useTranslation();

  return (
    <img
      src={schemaFiles.images.alertIcon}
      className={className}
      alt={t('Delete warning')}
    />
  );
};

export default WarningExclamation;
