import { t } from 'i18next';
import React from 'react';
import { useHistory } from 'react-router-dom';

import KdButton from '../../shared/KdButton';

const ErrorPageView: React.FC = () => {
  const history = useHistory();

  const handleClick = () => {
    history.push('/');
  };

  return (
    <main className="flex justify-center items-center px-[30px] h-[100vh]">
      <div className="text-center">
        <p className="text-[28px] text-primary mb-[30px] font-[700]">Oops!</p>
        <p className="text-[25px] text-white mb-[20px]">
          {t('Looks like something went wrong.')}
        </p>
        <div className="text-[20px] text-primary mb-[30px]">
          {t('Please try again.')}
        </div>
        <KdButton
          onClick={handleClick}
          fullOnMobile
        >
          {t('Return Home')}
        </KdButton>
      </div>
    </main>
  );
};
export default ErrorPageView;
