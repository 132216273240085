/* eslint-disable camelcase */
type awsconfigType = {
  aws_project_region: string;
  aws_cognito_region: string;
  aws_user_pools_id: string;
  aws_user_pools_web_client_id: string;
  oauth: any;
  aws_cloud_logic_custom: Array<any>;
};

export const awsconfig: awsconfigType = {
  aws_project_region: process.env.REACT_APP_REGION,
  aws_cognito_region: process.env.REACT_APP_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_USERPOOLID,
  aws_user_pools_web_client_id: process.env.REACT_APP_USERPOOLWEBCLIENTID,
  oauth: {},
  aws_cloud_logic_custom: [],
};

export const awsconfigLA: awsconfigType = {
  aws_project_region: process.env.REACT_APP_REGION,
  aws_cognito_region: process.env.REACT_APP_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_USERPOOLID_LA,
  aws_user_pools_web_client_id: process.env.REACT_APP_USERPOOLWEBCLIENTID_LA,
  oauth: {},
  aws_cloud_logic_custom: [],
};
