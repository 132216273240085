import { t } from 'i18next';
import { useEffect } from 'react';

import { useAuth } from '../../hooks/auth/useAuth';
import LoadingCircle from '../../shared/LoadingCircle';

const HomePage = () => {
  const { restoreUserDetails } = useAuth();
  useEffect(() => {
    restoreUserDetails();
  }, []);

  return <LoadingCircle text={t('Getting User Details')} />;
};

export default HomePage;
