import Typography from '@mui/material/Typography';
import cn from 'classnames';
import React from 'react';

interface Props {
  actions?: React.ReactNode;
  className?: string;
  description?: string;
  descriptionClassName?: string;
  pageTitle?: string | React.ReactNode;
}

const KdPageTitle: React.FC<Props> = ({
  actions,
  className,
  description,
  descriptionClassName,
  pageTitle,
}) => {
  const classes = cn(
    'text-[20px] sm:text-[28px] font-semibold mb-[20px]',
    className
  );

  const descriptionClasses = cn(
    'text-textXs sm:text-textBase text-contentColor leading-6',
    descriptionClassName
  );

  return (
    <>
      {pageTitle && (
        <Typography
          className={classes}
          component="h1"
          variant="h4"
        >
          {pageTitle}
        </Typography>
      )}
      {description && !actions && (
        <p className={`${descriptionClasses} mb-[15px] sm:mb-[30px]`}>
          {description}
        </p>
      )}
      {description && actions && (
        <div className="relative mb-[15px] sm:mb-[30px] pr-[230px]">
          <p className={descriptionClasses}>{description}</p>
          {actions && (
            <div className="absolute right-0 bottom-[-1px] z-10">{actions}</div>
          )}
        </div>
      )}
    </>
  );
};
export default KdPageTitle;
