import { useEffect, useState } from 'react';

import { covertBreakpointStringToNumber } from '../functions/utils';
import designTokenType from '../types/designTokenTypes';

const tokens: designTokenType = require('../theme/design-tokens.json');

const largeScreenBreakpoint = covertBreakpointStringToNumber(tokens.screens.lg);

export const mobileBreakpoint = covertBreakpointStringToNumber(
  tokens.screens.sm
);
export const smallScreenBreakpoint = covertBreakpointStringToNumber(
  tokens.screens.md
);

export const mediumScreenBreakpoint = 1200;

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
    isSmallScreen: width < smallScreenBreakpoint,
    isMediumScreen: width < mediumScreenBreakpoint,
    isMobile: width < mobileBreakpoint,
    isSmallerThanLarge: width < largeScreenBreakpoint,
  };
};

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};
export default useWindowDimensions;
