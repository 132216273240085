import React from 'react';
import { Helmet } from 'react-helmet';

import { SchemaColours, SchemaInfo } from './types/schema';

const schemaInfo: SchemaInfo = require(`./theme/${process.env.REACT_APP_THEME}/schemaInfo.json`);
const schemaColours: SchemaColours = require(`./theme/${process.env.REACT_APP_THEME}/schemaInfo.json`);
const schemaFiles =
  require(`./theme/${process.env.REACT_APP_THEME}/schemaFiles.ts`).default;

const Head = () => {
  return (
    <Helmet>
      {/* images */}
      <link
        rel="icon"
        href={schemaFiles.images.favicon}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={schemaFiles.images.favicon32}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={schemaFiles.images.favicon16}
      />

      <link
        rel="mask-icon"
        href="/safari-pinned-tab.svg"
        color={schemaColours.primary}
      />
      <meta
        name="msapplication-TileColor"
        content={schemaColours.primary}
      />
      <meta
        name="theme-color"
        content={schemaColours.primary}
      />

      {/* <!-- Primary Meta Tags --> */}
      <title>{schemaInfo.productName}</title>
      <meta
        name="title"
        content={schemaInfo.productName}
      />
      <meta
        name="description"
        content={schemaInfo.productDescription}
      />

      {/* <!-- Open Graph / Facebook --> */}
      <meta
        property="og:type"
        content="website"
      />
      <meta
        property="og:url"
        content={schemaInfo.productURL}
      />
      <meta
        property="og:title"
        content={schemaInfo.productName}
      />
      <meta
        property="og:description"
        content={schemaInfo.productDescription}
      />

      {/* <!-- Twitter --> */}
      <meta
        property="twitter:url"
        content={schemaInfo.productURL}
      />
      <meta
        property="twitter:title"
        content={schemaInfo.productName}
      />
      <meta
        property="twitter:description"
        content={schemaInfo.productDescription}
      />
    </Helmet>
  );
};

export default Head;
