export type BulkUploadEntityTypes = 'MOTOR_VEHICLE' | 'CARGO_BIKE' | 'DRIVER';

export type BulkUploadRequest = {
  entity_type: BulkUploadEntityTypes;
};

export type BulkUploadResponse = {
  correlation_id: string;
  presigned_upload_config: {
    url: string;
    fields: { [key: string]: string };
  };
  message?: string;
};

export type BulkUploadStatusRequest = {
  correlation_id: string;
  row_status?:
    | 'CREATED'
    | 'FAILED'
    | 'DUPLICATE'
    | 'NOT_CREATED'
    | 'CREATED_OR_DUPLICATE';
  page_number?: number;
  page_size?: number;
};

export type BulkUploadStatusResponse = {
  correlation_id: string;
  entity_type: string;
  status: {
    code: number;
    details: string;
    message: string;
  };
  row_counts: {
    total: number;
    processed: number;
    created: number;
    failed: number;
    skipped: number;
  };

  paging: {
    current_page: number;
    total_pages: number;
    page_size: number;
  };
  rows: BulkUploadServerError[];
};

export type BulkUploadServerError = {
  row_number: number;
  status: {
    code: number;
    detail: string;
    message: string;
  };
};

export type UploadStatus = {
  httpStatus: number;
  body: BulkUploadStatusResponse;
};

// possible value for UploadStatus.httpStatusCode
export const HTTP_STATUS_COMPLETE = 207;
export const HTTP_STATUS_INPROGRESS = 202;

// these are the possible values for UploadStatus.responseBody.status.code
export const OVERALL_STATUS_SUCCESS = 201;
export const OVERALL_STATUS_DATAERROR = 400;
export const OVERALL_STATUS_SERVERERROR = 500;
