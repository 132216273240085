import { useState } from 'react';

import { ReactComponent as MoreIcon } from '../../../data/icons/more.svg';
import { MenuItemsType } from '../types/menuTypes';
import CollapseMenu from './CollapseMenu';

interface Props {
  itemWidth: number;
  isMoreButtonActive: boolean;
  menuItems: MenuItemsType;
  onClick: () => void;
}

const MenuMoreButton = ({
  itemWidth,
  isMoreButtonActive,
  menuItems,
  onClick,
}: Props) => {
  const [show, setShow] = useState(false);

  const handleShow = () => {
    setShow(!show);
    if (onClick) onClick();
  };

  const handleHide = () => {
    setShow(false);
  };

  return (
    <>
      <button
        className={`${isMoreButtonActive ? 'text-white' : 'text-gray-300'}`}
        data-testid="bottom-navigation-more-button"
        onClick={handleShow}
        style={{ width: `${itemWidth}px` }}
      >
        <MoreIcon
          className="mx-auto"
          data-testid="bottom-navigation-more-image"
        />
      </button>
      <CollapseMenu
        menuItems={menuItems}
        onClose={handleHide}
        show={show}
      />
    </>
  );
};

export default MenuMoreButton;
