import { ExpandLess } from '@mui/icons-material';
import { t } from 'i18next';
import { useMemo, useRef, useState } from 'react';

import helpImg from '../../../data/icons/help.svg';
import infoImg from '../../../data/icons/info.svg';
import logoutImg from '../../../data/icons/logout.svg';
import { useAuth } from '../../../hooks/auth/useAuth';
import useHandleClickOutside from '../../../hooks/useHandleClickOutside';
import InitialsBox from '../../../pages/ManageUsers/SharedComponents/InitialsBox';
import HeaderContainer from '../components/HeaderContainer';
import HeaderPageTitle from '../components/HeaderPageTitle';
import Logo from '../components/Logo';
import DropdownItem from './DropdownItem';

type Props = {
  pageTitle: string;
};

export type TopbarButtonType = {
  title: string;
  path?: string;
  icon: string;
  onClick?: Function;
  size?: string;
};

const HeaderDesktop = ({ pageTitle }: Props) => {
  const { organisation, userDetails, signOut } = useAuth();

  const [show, setShow] = useState(false);
  const clickOutsideRef = useRef(null);

  const handleClickOutside = () => {
    setShow(false);
  };

  useHandleClickOutside({
    ref: clickOutsideRef,
    handleClick: handleClickOutside,
  });

  const [selectedIndex, setSelectedIndex] = useState<number>(null);

  const handleClick = () => {
    setShow((prev) => !prev);
  };

  const ArrayOfButtons: Array<TopbarButtonType> = useMemo(
    () => [
      {
        title: t('Help'),
        path: '/help',
        icon: helpImg,
      },
      {
        title: t('About'),
        path: '/about',
        icon: infoImg,
      },
      {
        title: t('Logout'),
        onClick: signOut,
        icon: logoutImg,
        size: 'small',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <HeaderContainer testId="topbar">
      <div className="flex items-center max-w-[500px]">
        <Logo />
        <HeaderPageTitle
          className="ml-[30px] 2xl:ml-[40px]"
          pageTitle={organisation?.name}
        />
      </div>

      <div className="relative flex justify-center items-center h-full mr-[40px]">
        <div
          className="flex items-center cursor-pointer"
          role="button"
          tabIndex={0}
          onClick={handleClick}
          data-testid="topbar-dropdown-menu"
          ref={clickOutsideRef}
        >
          <div className="flex mr-[15px]">
            <InitialsBox
              index={1}
              userName={userDetails?.name}
              size="topbar"
            />
          </div>
          <ExpandLess
            fontSize="large"
            color="inherit"
            style={{
              transform: show ? 'rotate(0deg)' : 'rotate(180deg)',
              transition: 'all',
              transitionDuration: '200ms',
              transitionTimingFunction: 'ease-in-out',
            }}
          />

          {show && (
            <div className="absolute right-[9px] top-[calc(100%+10px)] flex flex-col w-[172px] border-[2px] border-borderColor bg-controlBg z-50 p-[10px] rounded-lg gap-[10px]">
              {ArrayOfButtons.map((e, i) => (
                <DropdownItem
                  key={e.title}
                  button={e}
                  isSelected={selectedIndex === i}
                  onHandleClick={() => {
                    setSelectedIndex(i);
                  }}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </HeaderContainer>
  );
};

export default HeaderDesktop;
