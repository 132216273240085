import featuresListDev from '../feature-flags/features-dev.json';
import featuresListProd from '../feature-flags/features-prod.json';
import featuresListStaging from '../feature-flags/features-stg.json';
import { featureFlagStoreActionType } from '../types/storeActions';

export const initialState = () => {
  const environmentType = process.env.REACT_APP_ENVIRONMENT;

  switch (environmentType) {
    case 'dev':
      return featuresListDev;
    case 'stg':
      return featuresListStaging;
    case 'prod':
      return featuresListProd;
    default:
      return {};
  }
};

const featureFlagStore = (
  state = initialState,
  action: featureFlagStoreActionType
) => {
  switch (action.type) {
    case 'SET_FEATURE_FLAG_STATUS':
      return {
        ...state,
        [action.payload.featureFlagLabel]:
          action.payload.featureFlagDisplayStatus,
      };
    default:
      return state;
  }
};

export interface FeatureFlags {
  deleteActiveBookings?: boolean;
  editVehicles?: boolean;
  deleteVehicles?: boolean;
  addVehicleRoute?: boolean;
  editVehicleRoute?: boolean;
  supportPhoneNumber?: boolean;
  createBooking?: boolean;
  laUserFlow?: boolean;
  showAlert?: boolean;
  showPricing?: boolean;
  useLocalisation?: boolean;
  errorReporting?: boolean;
}

export const initialFeatureFlagState: FeatureFlags = initialState();

export default featureFlagStore;
