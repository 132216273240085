import React from 'react';

import { resetSiteData } from '../../../functions/resetSiteData';
import { t } from '../../../i18n';
import Refresh from '../../../images/Refresh.svg';
import Update from '../../../images/Update.svg';
import KdButton from '../../KdButton';
import { ModalProps } from '../KdModal';
import MessageModal from '../MessageModal';

interface NotifierProps extends ModalProps {
  minVersion: number;
  currentVersion: number;
  onIgnore: () => void;
}

const VersionOutOfDateNotifier: React.FC<NotifierProps> = ({
  minVersion,
  currentVersion,
  onIgnore,
  show,
}) => {
  const refresh = () => {
    resetSiteData({});
  };

  const ignore = () => {
    if (onIgnore) {
      onIgnore();
    }
  };

  return (
    <MessageModal
      icon={
        <img
          src={Update}
          alt="update icon"
        />
      }
      description={`${t('You are currently using Version')} ${currentVersion}.
      ${t(
        'Please click refresh to install the latest update and ensure that all features continue to work as expected'
      )}
      .`}
      show={show}
      title={`${t(
        'New Update Available For Kerb Delivery - Version'
      )} ${minVersion}`}
      actions={
        <>
          <KdButton
            type="button"
            onClick={ignore}
            className="button --warning"
          >
            {t('Ignore')}
          </KdButton>
          <KdButton
            type="button"
            onClick={refresh}
            className="button"
          >
            {t('Refresh')}&nbsp;
            <img
              src={Refresh}
              alt="refresh icon"
            />
          </KdButton>
        </>
      }
    />
  );
};
export default VersionOutOfDateNotifier;
