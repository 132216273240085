import * as RR from 'react-redux';
import { Dispatch } from 'redux';

import { createBookingStoreActionTypes } from './store/createBookingStore';
import { manageVehicleStoreActionType } from './store/manageVehiclesStore';
import {
  manageBookingsStoreActionType,
  manageUserStoreActionType,
  viewStoreAction,
} from './types/storeActions';

type reduxStoreActions =
  | createBookingStoreActionTypes
  | viewStoreAction
  | manageBookingsStoreActionType
  | manageUserStoreActionType
  | manageVehicleStoreActionType
  | { type: 'RESET_STORE' }
  | {
      type: 'SET_FEATURE_FLAG_STATUS';
      payload: { [key: string]: any };
    };

export const useAppDispatch = () =>
  RR.useDispatch<Dispatch<reduxStoreActions>>();
