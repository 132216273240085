import LinearProgress from '@mui/material/LinearProgress';

import { getProgressColor } from '../../functions/bulkUpload';
import { SchemaColours } from '../../types/schema';

interface Props {
  failedRows: number;
  progressState: string;
  value: number;
}

const colours: SchemaColours = require(`../../theme/${process.env.REACT_APP_THEME}/schemaColours.json`);

const BulkUploadLinearProgress = ({
  failedRows,
  progressState,
  value,
}: Props) => {
  return (
    <LinearProgress
      variant="determinate"
      value={value}
      sx={{
        '& .MuiLinearProgress-bar': {
          bgcolor: getProgressColor(progressState, failedRows),
        },
      }}
      style={{
        backgroundColor: colours['secondary'],
        width: '100%',
        margin: '10px 0 20px 0',
        height: '2px',
      }}
    />
  );
};

export default BulkUploadLinearProgress;
