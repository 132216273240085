import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import getLandingPageButtons from '../../data/landingPageButtons';
import { useAuth } from '../../hooks/auth/useAuth';
import { useRedirect } from '../../hooks/useRedirect';
import i18n, { t } from '../../i18n';
import LandingPageButton from '../../shared/LandingPageButton';
import { RootState } from '../../store';
import LandingPageButtonInfo from '../../types/landingPageButtonInfo';
import { SchemaColours, SchemaFiles } from '../../types/schema';
import { useAppDispatch } from '../../useAppDispatch';

const schemaFiles: SchemaFiles =
  require(`./../../theme/${process.env.REACT_APP_THEME}/schemaFiles.ts`).default;
const schemaColours: SchemaColours = require(`./../../theme/${process.env.REACT_APP_THEME}/schemaColours.json`);

const LandingView = () => {
  const { userType, setUserType } = useAuth();
  const { redirectTo } = useRedirect();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const featureFlagStore = useSelector(
    (state: RootState) => state.featureFlagStore
  );
  const { laUserFlow } = featureFlagStore;
  const [buttons, setButtons] = useState<LandingPageButtonInfo[]>(
    getLandingPageButtons(t, laUserFlow)
  );

  useEffect(() => {
    // reset relevant store values when user lands back on Dashboard
    dispatch({
      type: 'SET_SUB_ROUTE_PROGRESS',
      payload: 0,
    });
    dispatch({
      type: 'SET_CURRENT_SUBROUTES_DATA_ARRAY',
      payload: [],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    i18n.loadNamespaces(['translation']).then(() => {
      setButtons(getLandingPageButtons(t, laUserFlow));
    });

    const newButtons: LandingPageButtonInfo[] = buttons.map(
      (button: LandingPageButtonInfo) => {
        if (button.userType === 'LA') {
          return {
            ...button,
            visible: laUserFlow,
          };
        }
        return button;
      }
    );
    setButtons(newButtons);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [laUserFlow]);

  function handleClick(href: string) {
    history.push(href);
  }

  const bgStyle =
    schemaFiles.images.backgroundImage &&
    schemaFiles.images.backgroundImageInLanding
      ? {
          backgroundImage: `url(${schemaFiles.images.backgroundImage})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }
      : {
          backgroundColor: schemaColours.backgroundColor,
          backgroundImage: 'none',
        };

  return (
    <section
      className="flex flex-col w-full h-screen items-center justify-center bg-rimary"
      style={bgStyle}
    >
      <h1 className={`text-accentPrimary] text-center mx-auto my-5`}>
        <span>{t('Please select your')} </span>
        <br className="visible sm:hidden" />
        <span>{t('organisation type')}</span>
      </h1>
      <div className="flex flex-col sm:flex-row gap-5">
        {buttons
          .filter((e) => e.visible)
          .map((userButton, index) => {
            return (
              <LandingPageButton
                className="mx-auto border-2 border-solid border-white hover:border-bermuda hover:text-black w-full aspect-square min-w-min"
                key={`${userButton.title}-${index}`}
                btn={userButton}
                onClick={(btn: LandingPageButtonInfo): void => {
                  setUserType(btn.userType);
                  redirectTo(btn.linkHref);
                }}
              />
            );
          })}
      </div>
    </section>
  );
};

export default LandingView;
