import { Suspense, lazy, useEffect, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';

import AppRouter from './router/AppRouter';
import KdModalController from './shared/Modals/HookModal/KdModalController';
import { RootState } from './store';
import { useAppDispatch } from './useAppDispatch';

const KdToastController = lazy(
  () => import('./shared/Toast/KdToastController')
);
const AlertPanel = lazy(() => import('./shared/AlertPanel'));

const App = () => {
  const dispatch = useAppDispatch();

  const viewStore = useSelector((state: RootState) => state.viewStore);
  const featureFlagStore = useSelector(
    (state: RootState) => state.featureFlagStore
  );

  const { alert } = viewStore;
  const { showAlerts } = featureFlagStore;

  const reduxStore = localStorage.getItem('reduxStore');

  const resetInnerWidth = () => {
    document.documentElement.style.setProperty(
      '--windowInnerHeight',
      `${window.innerHeight}px`
    );
  };

  useLayoutEffect(() => {
    window.addEventListener('resize', resetInnerWidth);
    resetInnerWidth();
    return () => {
      window.removeEventListener('resize', resetInnerWidth);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (JSON.parse(reduxStore) === null) {
      dispatch({
        type: 'RESET_STORE',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxStore]);

  return (
    <Suspense fallback={<div>...</div>}>
      {alert.message && showAlerts && (
        <AlertPanel
          incomingAlert={alert}
          data-testid="alert-panel"
        />
      )}
      <KdToastController />
      <KdModalController />
      <AppRouter />
    </Suspense>
  );
};

export default App;
