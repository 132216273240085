import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import { Amplify, Auth, Hub } from 'aws-amplify';

import { awsconfig, awsconfigLA } from '../../aws-exports';
import { initGoogleAnalytics, initHotJar } from '../../functions/utils';
import { AuthTokens } from './types/authTokens';

export * from 'amazon-cognito-identity-js';
const enviorment = process.env.REACT_APP_ENVIRONMENT?.toLowerCase() || 'dev';
const authConfig = {
  Auth:
    enviorment === 'dev'
      ? {} // don't use cookieStorage for PRs
      : {
          // update the storage property to use cookies instead of LocalStorage
          cookieStorage: {
            domain: 'kerb-delivery.com',
            expires: 1,
            sameSite: 'strict',
            secure: true,
          },
        },
};

export class AwsInstance {
  static instance: AwsInstance;

  private cognitoUser?: CognitoUser;
  private cognitoSession?: CognitoUserSession;
  private tokens?: AuthTokens;
  private awsConfig: any;
  private tempValue: string;

  private signInHandler: (challenge: string) => void;
  private signOutHandler: () => void;
  private restoreHandler: () => void;
  private restoreErrorHandler: (error: any) => void;
  private areHandlersReady: boolean;

  public static getInstance(): AwsInstance {
    if (!AwsInstance.instance) {
      this.instance = new AwsInstance();
      const self = this.instance;

      self.areHandlersReady = false;

      // default to saved userType and if not fallback to CF
      const savedUserType = localStorage.getItem('userType') as
        | 'CF'
        | 'LA'
        | null;
      if (savedUserType) {
        self.resetByUserType(savedUserType);
      } else {
        self.resetByUserType('CF');
      }

      if (
        // only init analytics on production domain.
        process.env.NODE_ENV === 'production' &&
        window.location.href.includes('kerb-delivery.com')
      ) {
        initGoogleAnalytics();
        initHotJar();
      }
    }
    return AwsInstance.instance;
  }

  public async setEventHandlers({
    signInHandler = (challenge) => {
      console.info(`signInHandler not set challenge:${challenge}`);
    },
    signOutHandler = () => {
      console.info(`signOutHandler not set`);
    },
    restoreHandler = () => {
      console.info(`restoreHandler not set`);
    },
    restoreErrorHandler = (error) => {
      console.info(`restoreErrorHandler not set error:${error}`, error);
    },
  }: {
    signInHandler: (challenge: string) => void;
    signOutHandler: () => void;
    restoreHandler: () => void;
    restoreErrorHandler: (error: any) => void;
  }) {
    if (!this.areHandlersReady) {
      this.signInHandler = signInHandler;
      this.signOutHandler = signOutHandler;
      this.restoreHandler = restoreHandler;
      this.restoreErrorHandler = restoreErrorHandler;
      this.areHandlersReady = true;
      const self = this;
      Hub.listen('auth', async (value: any) => {
        // TODO: Keep this for now, later replace with Sentry logging
        if (value.payload.event === 'signIn') {
          if (self.signInHandler) {
            self.signInHandler(self.cognitoUser?.challengeName);
          }
        }
        if (value.payload.event === 'signOut') {
          self.cognitoUser = null;
          self.cognitoSession = null;
          self.tokens = null;
          if (self.signOutHandler) {
            self.signOutHandler();
          }
        }
        if (value.payload.event === 'configured') {
          if (self.restoreHandler) {
            try {
              self.signOutHandler();
            } catch (error) {
              if (self.restoreErrorHandler) {
                self.restoreErrorHandler(error);
              }
            }
          }
        }
      });
    }
  }

  public async restoreSession() {
    this.cognitoUser = await Auth.currentAuthenticatedUser();
    this.cognitoSession = await Auth.currentSession();

    this.tokens = {
      idToken: this.cognitoSession?.getIdToken().getJwtToken(),
      accessToken: this.cognitoSession?.getAccessToken().getJwtToken(),
      refreshToken: this.cognitoSession?.getRefreshToken().getToken(),
    } as AuthTokens;
  }

  public resetByUserType(userType: 'CF' | 'LA') {
    this.awsConfig =
      userType === 'CF'
        ? { ...awsconfig, ...authConfig }
        : { ...awsconfigLA, ...authConfig };
    Amplify.configure(this.awsConfig);
    Auth.configure(this.awsConfig);
  }

  public getCognitoUser = (): CognitoUser | undefined => this.cognitoUser;
  public getTokens = (): AuthTokens => this.tokens;
  public getCognitoSession = (): CognitoUserSession | undefined =>
    this.cognitoSession;

  public getToken = (tokenName: string = 'idToken') => {
    try {
      return this.tokens[tokenName];
    } catch (error) {
      return null;
    }
  };

  public isLoggedIn = (): boolean => {
    return !!this.cognitoSession?.isValid();
  };

  public getTempValue = (): string => {
    const returnValue = this.tempValue;
    this.setTempValue('');
    return returnValue;
  };

  public setTempValue = (value: string) => {
    this.tempValue = value;
  };
}
