import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { callCheckMinVersion } from '../api/kerb';
import {
  convertToVersionNumber,
  shouldUpgradeVersion,
} from '../functions/utils';
import { useAuth } from '../hooks/auth/useAuth';
import useHandleToast from '../hooks/useHandleToast';
import KdBreakpoints from './Breakpoints/breakpoint';
import VersionOutOfDateNotifier from './Modals/VersionOutOfDateNotifier';

interface responseType {
  ok: boolean;
  json: Function;
}

const UpdateChecker = (): JSX.Element => {
  const { isLoggedIn } = useAuth();
  const [minVersion, setMinVersion] = useState(NaN);
  const [currentVersion, setCurrentVersion] = useState(NaN);
  const [ignoreUpdate, setIgnoreUpdate] = useState(false);
  const { handleToast } = useHandleToast();
  const checkVersion = () => {
    callCheckMinVersion()
      .then((res: responseType) => {
        if (!res.ok) {
          throw res.json();
        }
        return res.json();
      })
      // eslint-disable-next-line camelcase
      .then((responseData: { min_kd_admin_web_version: string }) => {
        setMinVersion(
          convertToVersionNumber(responseData.min_kd_admin_web_version)
        );
        setCurrentVersion(
          convertToVersionNumber(process.env.REACT_APP_VERSION)
        );
      })
      .catch((err: Error) => {
        handleToast({ content: err?.message });
      });
  };

  useEffect(() => {
    if (isLoggedIn) checkVersion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  return (
    <VersionOutOfDateNotifier
      show={
        shouldUpgradeVersion(currentVersion, minVersion) &&
        ignoreUpdate === false
      }
      minVersion={minVersion}
      currentVersion={currentVersion}
      onIgnore={() => setIgnoreUpdate(true)}
    />
  );
};

export default UpdateChecker;
