import LoadingCircle from '../../shared/LoadingCircle';

const SuspenseFallbackPublic = () => {
  return (
    <div className="flex flex-1 justify-center items-center h-[100vh]">
      <LoadingCircle />
    </div>
  );
};
export default SuspenseFallbackPublic;
