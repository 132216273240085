/* eslint-disable no-unused-vars */
export const enum VehicleTypesEnum {
  ALL = 'ALL',
  MOTOR_VEHICLE = 'MOTOR_VEHICLE',
  CARGO_BIKE = 'CARGO_BIKE',
}

export const enum BookingTypesEnum {
  UPCOMING = 'UPCOMING',
  ACTIVE = 'ACTIVE',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
}

export const enum FuelTypesEnum {
  DIESEL = 'DIESEL',
  ELECTRICITY = 'ELECTRICITY',
  HYBRID_ELECTRIC = 'HYBRID ELECTRIC',
  PETROL = 'PETROL',
}

export const enum BulkUploadProgressStatusEnum {
  UPLOADING = 'UPLOADING',
  COMPLETE = 'COMPLETE',
  COMPLETE_PARTIALLY = 'COMPLETE_PARTIALLY',
  STOPPED_DATA = 'STOPPED_DATA',
  STOPPED_SERVER = 'STOPPED_SERVER',
}
