import Typography from '@mui/material/Typography';
import React from 'react';

import WarningExclamation from '../../../images/WarningExclamation';
import KdModal, { ModalProps } from '../KdModal';

interface MessageModalProps extends ModalProps {
  actions?: React.ReactNode;
  description?: string;
  subDescription?: string;
  icon?: React.ReactNode;
  title: string;
}

const descriptionClasses = `
  text-textXs
  leading-snug
  sm:text-textBase
`;

const actionsChildenClasses = `
  child:m-0 
  child:w-full
  nextChild:mt-[10px] 
  sm:child:w-auto
  sm:nextChild:mt-0
`;

const MessageModal: React.FC<MessageModalProps> = ({
  actions,
  description,
  icon = <WarningExclamation className="w-[35px] sm:w-[50px]" />,
  subDescription,
  show,
  title,
  ...props
}) => {
  return (
    <KdModal
      show={show}
      className="text-center"
      {...props}
    >
      <div className="mb-[10px] sm:mb-[30px] flex justify-center">{icon}</div>
      <Typography
        className="text-[22px] sm:text-[25px] font-semibold"
        component="h4"
      >
        {title}
      </Typography>
      {description && (
        <p className={`${descriptionClasses} mt-[10px]`}>{description}</p>
      )}
      {subDescription && (
        <p className={`${descriptionClasses} mt-[5px]`}>{subDescription}</p>
      )}
      {actions && (
        <div
          className={`sm:flex 
            sm:justify-center 
            gap-[30px] 
            mt-[20px] 
            sm:mt-[30px] 
            ${actionsChildenClasses}
          `}
        >
          {actions}
        </div>
      )}
    </KdModal>
  );
};

export default MessageModal;
