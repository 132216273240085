import { viewStoreAction } from '../types/storeActions';
import { viewStoreType } from '../types/stores';

export const initialViewState: viewStoreType = {
  alert: {
    message: null,
    isError: false,
  },
  subRouteProgress: 0,
  subRouteData: [],
  headerButton: '',
  logoutModal: false,
  bulkUploadSuccessType: null,
  dataOutOfSync: false,
  toastData: {
    content: null,
    variant: 'error',
    isDisplayed: false,
    noTopBar: false,
    title: null,
  },
  modalData: {
    show: false,
    variant: 'warning',
    title: null,
    description: null,
    buttonsProps: null,
  },
  selectedHighlight: { upper: -1, lower: -1 },
};

const viewStore = (state = initialViewState, action: viewStoreAction) => {
  switch (action.type) {
    case 'SET_SUB_ROUTE_PROGRESS':
      return {
        ...state,
        subRouteProgress: action.payload,
      };
    case 'SET_CURRENT_SUBROUTES_DATA_ARRAY':
      return {
        ...state,
        subRouteData: action.payload,
      };
    case 'SET_HEADER_BUTTON_COMPONENT':
      return {
        ...state,
        headerButton: action.payload,
      };
    case 'SET_ERROR_ALERT':
      return {
        ...state,
        alert: {
          message: action.payload,
          isError: true,
        },
      };
    case 'SET_ALERT':
      return {
        ...state,
        alert: {
          message: action.payload,
          isError: false,
        },
      };
    case 'SET_BULK_UPLOAD_SUCCESS_TYPE':
      return {
        ...state,
        bulkUploadSuccessType: action.payload,
      };
    case 'SET_DATA_OUT_OF_SYNC':
      return {
        ...state,
        dataOutOfSync: action.payload,
      };
    case 'SET_TOAST_DATA':
      return {
        ...state,
        toastData: action.payload,
      };
    case 'SET_SELECTED_HIGHLIGHT':
      return {
        ...state,
        selectedHighlight: action.payload,
      };
    case 'SET_MODAL_DATA':
      return {
        ...state,
        modalData: action.payload,
      };
    default:
      return state;
  }
};

export default viewStore;
