import { manageBookingsStoreActionType } from '../types/storeActions';
import { manageBookingStoreType } from '../types/stores';

export const initialManageBookingState: manageBookingStoreType = {
  selectedBooking: {},
  currentDisplayedBookings: [],
  filterType: null,
  selectedDate: null,
  bookingsLoading: false,
  leftPanelComponent: 'BASE',
  newBookingTime: null,
  newVRM: { id: null, vrm: null },
};

const manageBookingsStore = (
  state = initialManageBookingState,
  action: manageBookingsStoreActionType
) => {
  switch (action.type) {
    case 'SET_BOOKINGS_LOADING':
      return {
        ...state,
        bookingsLoading: action.payload,
      };
    case 'SET_SELECTED_BOOKING':
      return {
        ...state,
        selectedBooking: action.payload,
      };
    case 'SET_CURRENT_DISPLAYED_BOOKINGS':
      return {
        ...state,
        currentDisplayedBookings: action.payload,
      };
    case 'SET_FILTER':
      return {
        ...state,
        filterType: action.payload,
      };
    case 'SET_SELECTED_DATE':
      return {
        ...state,
        selectedDate: action.payload,
      };
    case 'SET_LEFT_PANEL_COMPONENT':
      return {
        ...state,
        leftPanelComponent: action.payload,
      };
    case 'SET_EDIT_BOOKING_TIME':
      return {
        ...state,
        newBookingTime: action.payload,
      };
    case 'SET_NEW_VRM':
      return {
        ...state,
        newVRM: action.payload,
      };
    default:
      return state;
  }
};

export default manageBookingsStore;
