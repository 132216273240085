import { format as formatDate } from 'date-fns';
import { enGB, nl } from 'date-fns/locale';
import React from 'react';
import { useTranslation } from 'react-i18next';

const locales: { [key: string]: Locale } = {
  en: enGB,
  nl: nl,
};

const LocalisedView = () => {
  const { t, i18n } = useTranslation();

  return (
    <div
      className={`relative w-full h-full flex flex-col items-center justify-center mx-auto my-auto`}
    >
      <span
        className={`flex mt-2 font-black text-3xl`}
        data-testid="first-phrase"
      >
        {t('Welcome To Localisation Sample Page')}
      </span>
      <br />
      <span
        className={`flex mt-2 font-black text-sm`}
        data-testid="second-phrase"
      >
        {t('Today is ')}
        {formatDate(new Date(), 'PPPP', {
          locale: locales[i18n?.language],
        })}
      </span>
      <div className="flex flex-row gap-4 mt-10">
        <button
          className="btn-primary"
          onClick={() => {
            i18n.changeLanguage('en-GB');
          }}
        >
          English UK
        </button>
        <button
          className="btn-primary"
          onClick={() => {
            i18n.changeLanguage('nl');
          }}
          data-testid="dutch-button"
        >
          Dutch
        </button>
      </div>
      <div className="mt-10 animate-pulse">
        {t('Locale code is ')} {i18n.language}
      </div>
    </div>
  );
};
LocalisedView.defaultProps = {
  className: undefined,
  text: '',
  textClassName: undefined,
};

export default LocalisedView;
