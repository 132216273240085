import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { SchemaInfo } from '../types/schema';
import { useAppDispatch } from '../useAppDispatch';

const schemaInfo: SchemaInfo = require(`../theme/${process.env.REACT_APP_THEME}/schemaInfo.json`);

type PreRouteProps = {
  children: React.ReactNode;
  route: {
    component: any;
    isPrivate: boolean;
    mobileMenu?: boolean;
    featureFlagId?: string;
    title?: string;
    path: string;
    topbar?: {
      headerBarButton?: any;
      hasLogoutButton?: boolean;
      isDark?: boolean;
    };
    props?: {
      to?: string;
    };
  };
};

const PreRoute = ({ children, route }: PreRouteProps) => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const updateWindowTitle = () => {
    document.title = `${route.title} | ${schemaInfo.productName}`;
  };

  const updateHeaderButton = () => {
    const { topbar = {} } = route;
    const { headerBarButton = null } = topbar;

    dispatch({
      type: 'SET_HEADER_BUTTON_COMPONENT',
      payload: headerBarButton,
    });
  };

  useEffect(() => {
    updateWindowTitle();
    updateHeaderButton();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return <>{children}</>;
};

export default PreRoute;
