import cn from 'classnames';
import { ReactNode } from 'react';

import useWindowDimensions from '../../hooks/useWindowDimensions';
import { MobileContentBody } from './ui/MobileContentBody';
import { MobileContentFooter } from './ui/MobileContentFooter';
import { MobileContentHeader } from './ui/MobileContentHeader';

type Props = {
  className?: string;
  mobileClassName?: string;
  mobileHeaderClassName?: string;
  mobileContentClassName?: string;
  mobileFooterClassName?: string;
  desktopBody?: ReactNode;
  mobileHeader?: ReactNode;
  mobileBody?: ReactNode;
  mobileFooter?: ReactNode;
  onEndOfMobilePage?: () => void;
  isLoading?: boolean;
  isStickyEffect?: boolean;
};

const ContentLayout = ({
  className,
  mobileClassName,
  mobileHeaderClassName,
  mobileContentClassName,
  mobileFooterClassName,
  desktopBody,
  mobileBody,
  mobileHeader,
  mobileFooter,
  onEndOfMobilePage,
  isLoading,
  isStickyEffect,
}: Props) => {
  const { isMobile } = useWindowDimensions();

  const mobileClasses = cn(
    isStickyEffect ? 'max-h-full pt-[30px] !block' : 'h-[calc(100%-70px)]',
    mobileClassName
  );

  return (
    <div
      data-testid="pageContent"
      className={cn(
        'flex flex-col',
        isMobile ? mobileClasses : 'xl:px-[20px] 2xl:px-[120px]',
        className
      )}
    >
      <>
        {!isMobile && <>{desktopBody}</>}
        {isMobile && (
          <>
            {mobileHeader && (
              <MobileContentHeader className={mobileHeaderClassName}>
                {mobileHeader}
              </MobileContentHeader>
            )}
            <MobileContentBody
              className={mobileContentClassName}
              onEndOfListReached={onEndOfMobilePage}
              isLoading={isLoading}
            >
              {mobileBody}
            </MobileContentBody>
            <MobileContentFooter className={mobileFooterClassName}>
              {mobileFooter}
            </MobileContentFooter>
          </>
        )}
      </>
    </div>
  );
};

export default ContentLayout;
