import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import KdDropdown from '../Dropdown/KdDropdown';
import LoadingCircle from '../LoadingCircle';
import KdPagingArrowPicker, { PagingProps } from './KdArrowPicker';

interface Props extends PagingProps {
  data: Array<any>;
  renderItems: (data: any) => React.ReactNode;
  title?: string;
  isLoading?: boolean;
  arrowPagingClassName?: string;
  className?: string;
}

const KdPaging: React.FC<Props> = ({
  arrowPagingClassName,
  className,
  data,
  isLoading,
  totalPages,
  pageNumber,
  renderItems,
  setPageNumber,
  title,
}) => {
  const { t } = useTranslation();

  const handleChange = (value: string) => {
    setPageNumber(parseInt(value) ?? 0);
  };

  const options = [...Array(totalPages).keys()].map((number: number) => ({
    key: String(number + 1),
    label: String(number + 1),
  }));

  const classes = cn('flex flex-col w-full', className);

  return (
    <div className={classes}>
      <div className="flex justify-between items-center mb-[30px]">
        <div className="text-[23px] text-contentColor">{title}</div>
        <div className="flex items-center">
          <div className="mr-[10px] text-textBase">{t('Jump to page')}</div>
          <KdDropdown
            value={String(pageNumber)}
            onChange={handleChange}
            options={options}
            buttonClassName="!h-[40px] !px-[15px] min-w-[75px]"
            menuClassName="!px-[15px] max-h-[200px] overflow-y-auto"
            testId="paging-dropdown"
          />
        </div>
      </div>
      {isLoading ? (
        <LoadingCircle className="mt-[50px]" />
      ) : (
        <>{renderItems(data)}</>
      )}
      <KdPagingArrowPicker
        className={arrowPagingClassName}
        totalPages={totalPages}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
      />
    </div>
  );
};
export default KdPaging;
