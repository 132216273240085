import { ReactComponent as AnalyticsIcon } from '../../../data/icons/analytics.svg';
import { ReactComponent as BookingsIcon } from '../../../data/icons/bookings.svg';
import { ReactComponent as DashboardIcon } from '../../../data/icons/home.svg';
import { ReactComponent as OrganisationIcon } from '../../../data/icons/organisation.svg';
import { ReactComponent as UserIcon } from '../../../data/icons/user.svg';
import { ReactComponent as VehicleIcon } from '../../../data/icons/van.svg';

const defaultSize = 'w-[30px] h-[30px]';

export const getMenuIcon = (icon: string) => {
  switch (icon) {
    case 'dashboard':
      return <DashboardIcon className={defaultSize} />;
    case 'bookings':
      return <BookingsIcon className={defaultSize} />;
    case 'vehicles':
      return <VehicleIcon className={defaultSize} />;
    case 'users':
      return <UserIcon className="w-[35px] h-[35px]" />;
    case 'analytics':
      return <AnalyticsIcon className="w-[26px] h-[26px]" />;
    case 'organisation':
      return <OrganisationIcon className={defaultSize} />;
    default:
      return;
  }
};

const dashboardSize = 'w-[50px] h-[50px]';
const dashboardIconSize = `${dashboardSize} sm:w-[30px] sm:h-[30px]`;

export const getMenuDashboardIcon = (icon: string) => {
  switch (icon) {
    case 'bookings':
      return <BookingsIcon className={dashboardIconSize} />;
    case 'vehicles':
      return <VehicleIcon className={dashboardIconSize} />;
    case 'users':
      return (
        <UserIcon className={`${dashboardSize} sm:w-[35px] sm:h-[35px]`} />
      );
    case 'analytics':
      return (
        <AnalyticsIcon className={`${dashboardSize} sm:w-[26px] sm:h-[26px]`} />
      );
    default:
      return;
  }
};
