import React from 'react';
import { ReactSVG } from 'react-svg';

import LandingPageButtonInfo from '../types/landingPageButtonInfo';
import KdButton from './KdButton';

type Props = {
  btn: LandingPageButtonInfo;
  onClick: (btn: LandingPageButtonInfo) => void;
  onHover?: (btn: LandingPageButtonInfo) => void;
  onHoverEnd?: (btn: LandingPageButtonInfo) => void;
  className?: string;
};
const LandingPageButton = ({
  btn,
  onClick,
  onHover,
  onHoverEnd,
  className,
}: Props): JSX.Element => {
  const [hover, setHover] = React.useState(false);

  return (
    <KdButton
      className={`text-dashboardButtonText bg-dashboardButtonBackground ${className}`}
      type="button"
      onClick={() => {
        if (onClick) {
          onClick(btn);
        }
      }}
      onMouseEnter={() => {
        setHover(true);
        if (onHover) {
          onHover(btn);
        }
      }}
      onMouseLeave={() => {
        setHover(false);
        if (onHoverEnd) {
          onHoverEnd(btn);
        }
      }}
      testId={btn.testId}
    >
      <span className="text-left">{btn.title}</span>
      <ReactSVG
        wrapper="svg"
        src={btn.img}
        className={`w-[70px] h-[70px] cursor-pointer mx-auto m-3
          ${
            hover
              ? `stroke:dashboardButtonTextHover`
              : `stroke-dashboardButtonText`
          }
          ${
            hover ? `fill:dashboardButtonTextHover` : `fill-dashboardButtonText`
          }
          `}
        width="100px"
        height="100px"
        onClick={() => {
          if (onClick) {
            onClick(btn);
          }
        }}
      />
    </KdButton>
  );
};

export default LandingPageButton;
