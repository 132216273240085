import { manageUserStoreActionType } from '../types/storeActions';
import { manageUserStoreType } from '../types/stores';

const initialState: manageUserStoreType = {
  newUserType: null,
  newUserDetails: {},
  userTableOutOfSync: false,
  usersToDelete: [],
  selectedUserIndex: 0,
};

export const initialManageUserState: manageUserStoreType = {
  newUserType: null,
  newUserDetails: {},
  userTableOutOfSync: false,
  usersToDelete: [],
  selectedUserIndex: 0,
};

const manageUserStore = (
  state = initialState,
  action: manageUserStoreActionType
) => {
  switch (action.type) {
    case 'SET_NEW_USER_TYPE':
      return {
        ...state,
        newUserType: action.payload,
      };
    case 'SET_NEW_USER_DETAILS':
      return {
        ...state,
        newUserDetails: { ...action.payload },
      };
    case 'SET_USERS_TABLE_OUT_OF_SYNC':
      return {
        ...state,
        userTableOutOfSync: action.payload,
      };
    case 'SET_USERS_TO_DELETE':
      return {
        ...state,
        usersToDelete: action.payload,
      };
    case 'SET_SELECTED_USER_INDEX':
      return {
        ...state,
        selectedUserIndex: action.payload,
      };
    default:
      return state;
  }
};

export default manageUserStore;
