import { useLocation } from 'react-router-dom';

import { ReactComponent as HighlightIcon } from '../../../data/icons/light.svg';
import { SelectedHighlightType } from '../types/menuTypes';

interface Props {
  selectedHighlight: SelectedHighlightType;
}

const svgHeight = 80;
const rem = 17 * 0.25;
const topMargin = '30px';
const topMarginForSVG = 9;
const paddingBottom = '30px';
const bottomSVGSize = '30px / 2';

const HighlightDesktop = ({ selectedHighlight }: Props) => {
  const location = useLocation();
  const isAboutPage = location.pathname === '/about';

  if (isAboutPage) {
    return null;
  }

  return (
    <HighlightIcon
      className="absolute left-[-1px] top-0 cursor-pointer pointer-events-none transition-[transform] delay-100 duration-[400ms]"
      data-testid="highlight"
      width="80"
      height="94"
      style={{
        transform: `translateY(${
          selectedHighlight.lower < 0
            ? `${
                selectedHighlight.upper * svgHeight +
                rem * selectedHighlight.upper +
                topMarginForSVG
              }px`
            : `calc(100% - ${topMargin} - ${paddingBottom} + ${bottomSVGSize})`
        })`,
      }}
    />
  );
};

export default HighlightDesktop;
