import { lazy } from 'react';
import { Redirect } from 'react-router-dom';

import BulkUploadErrors from '../pages/AddVehiclesNew/AddMultiple/BulkUploadErrors';
import BulkUploadProgress from '../pages/AddVehiclesNew/AddMultiple/BulkUploadProgress';
import ErrorPageView from '../pages/ErrorPage/ErrorPageView';
import HomePage from '../pages/Home/HomePage';
import LandingView from '../pages/Landing/LandingView';
import BulkUploadUsersErrors from '../pages/ManageUsers/CreateUsers/BulkUploadUsersErrors';
import LocalisedView from '../shared/LocalisedView';
import BackButton from '../shared/TopBar/components/BackButton';
import { t } from './../i18n';

const LoginPage = lazy(() => import('../pages/Login/LoginPage'));

const LoginLaPage = lazy(() => import('../pages/LoginLa/LoginLaPage'));

const RegisterPage = lazy(() => import('../pages/Register/RegisterPage'));

const ConfirmationCodePage = lazy(
  () => import('../pages/ConfirmationCode/ConfirmationCodePage')
);

const ResetPasswordPage = lazy(
  () => import('../pages/ResetPassword/ResetPasswordPage')
);

const NewPasswordPage = lazy(
  () => import('../pages/NewPassword/NewPasswordPage')
);

const Dashboard = lazy(() => import('../pages/Dashboard/Dashboard'));

const AddOrganisationPage = lazy(
  () => import('../pages/AddOrganisation/AddOrganisationPage')
);

const EditOrganisationPage = lazy(
  () => import('../pages/EditOrganisation/EditOrganisationPage')
);

const HelpView = lazy(() => import('../pages/Help/HelpView'));

const BookingsPage = lazy(() => import('../pages/BookingsPage/BookingsPage'));

const CreateBookingsPage = lazy(
  () => import('../pages/CreateBookings/CreateBookingsPage')
);

const DeveloperToolsView = lazy(
  () => import('../pages/DeveloperTools/DeveloperToolsView')
);

const AddVehicles = lazy(() => import('../pages/AddVehiclesNew/AddVehicles'));

const ExportProcessPage = lazy(
  () => import('../pages/Analytics/Shared/ExportProcessPage')
);

const AnalyticsPageLa = lazy(
  () => import('../pages/Analytics/LA/AnalyticsPage')
);

const AnalyticsPageCf = lazy(
  () => import('../pages/Analytics/CF/AnalyticsPage')
);

const AboutPage = lazy(() => import('../pages/About/AboutPage'));

const CreateUsers = lazy(
  () => import('../pages/ManageUsers/CreateUsers/CreateUsers')
);

const ManageUsersView = lazy(
  () => import('../pages/ManageUsers/ManageUsersView')
);

const EditUsers = lazy(() => import('../pages/ManageUsers/EditUsers'));

const ManageVehiclesPage = lazy(
  () => import('../pages/ManageVehicles/ManageVehiclesPage')
);

const BulkUploadUsersProgress = lazy(
  () => import('../pages/ManageUsers/CreateUsers/BulkUploadUsersProgress')
);

const EditDeleteVehicle = lazy(
  () => import('../pages/ManageVehicles/EditDeleteVehicle/EditDeleteVehicle')
);

const routes = [
  {
    path: '/',
    title: t('Welcome'),
    component: HomePage,
    isPrivate: false,
  },
  {
    path: '/landing',
    title: t('Organization Type'),
    component: LandingView,
    isPrivate: false,
  },
  {
    path: '/login',
    title: t('Login'),
    component: LoginPage,
    isPrivate: false,
  },
  {
    path: '/login-la',
    title: t('Login (LA)'),
    component: LoginLaPage,
    isPrivate: false,
    featureFlagId: 'laUserFlow',
  },
  {
    path: '/dashboard',
    title: t('Dashboard'),
    component: Dashboard,
    isPrivate: true,
    topbar: {
      isDark: false,
      hasLogoutButton: true,
    },
  },
  {
    path: '/addorganisation',
    title: t('Add Organisation'),
    component: AddOrganisationPage,
    isPrivate: true,
    hideMenu: true,
  },
  {
    path: '/addvehicle',
    title: t('Add to Fleet'),
    component: AddVehicles,
    isPrivate: true,
    topbar: { headerBarButton: BackButton },
    featureFlagId: 'addVehicleRoute',
  },
  {
    path: '/addnewusers/progress',
    title: t('Add Multiple Users'),
    component: BulkUploadUsersProgress,
    isPrivate: true,
    topbar: { headerBarButton: BackButton },
  },
  {
    path: '/addnewusers/errors',
    title: t('Add Multiple Users'),
    component: BulkUploadUsersErrors,
    isPrivate: true,
    topbar: { headerBarButton: BackButton },
  },
  {
    path: '/addvehicle/progress',
    title: t('Add to Fleet'),
    component: BulkUploadProgress,
    isPrivate: true,
    topbar: { headerBarButton: BackButton },
    featureFlagId: 'addVehicleRoute',
  },
  {
    path: '/addvehicle/errors',
    title: t('Errors'),
    component: BulkUploadErrors,
    isPrivate: true,
    topbar: { headerBarButton: BackButton },
    featureFlagId: 'addVehicleRoute',
  },
  {
    path: '/managevehicles',
    title: t('Manage Fleet'),
    component: ManageVehiclesPage,
    isPrivate: true,
    topbar: { headerBarButton: BackButton },
    mobileMenu: true,
  },
  {
    path: '/edit-delete-vehicle/:id',
    title: t('Manage Fleet'),
    component: EditDeleteVehicle,
    isPrivate: true,
    topbar: { headerBarButton: BackButton },
    mobileMenu: true,
  },
  {
    path: '/editorganisation',
    title: t('Edit Organisation'),
    component: EditOrganisationPage,
    isPrivate: true,
    topbar: { headerBarButton: BackButton },
  },
  {
    path: '/register',
    title: t('Register'),
    component: RegisterPage,
    isPrivate: false,
  },
  {
    path: '/confirm',
    title: t('Confirmation Code'),
    component: ConfirmationCodePage,
    isPrivate: false,
  },
  {
    path: '/error',
    title: t('Error'),
    component: ErrorPageView,
    isPrivate: false,
  },
  {
    path: '/resetpassword',
    title: t('Reset Password'),
    component: ResetPasswordPage,
    isPrivate: false,
  },
  {
    path: '/newpassword',
    title: t('New Password'),
    component: NewPasswordPage,
    isPrivate: false,
  },
  {
    path: '/createbooking',
    title: t('Create a Booking'),
    component: CreateBookingsPage,
    isPrivate: true,
    topbar: { headerBarButton: BackButton },
    mobileMenu: true,
  },

  {
    path: '/editbooking',
    title: t('Create a Booking'),
    component: CreateBookingsPage,
    isPrivate: true,
    topbar: { headerBarButton: BackButton },
    mobileMenu: true,
  },
  {
    path: '/manageusers',
    title: t('Manage Users'),
    component: ManageUsersView,
    isPrivate: true,
    topbar: { headerBarButton: BackButton },
    mobileMenu: true,
  },
  {
    path: '/help',
    title: t('Help'),
    component: HelpView,
    isPrivate: true,
    hideMenu: true,
    topbar: { headerBarButton: BackButton },
    mobileMenu: true,
  },
  {
    path: '/bookings',
    title: t('Bookings'),
    component: BookingsPage,
    isPrivate: true,
    topbar: { headerBarButton: BackButton },
    mobileMenu: true,
  },
  {
    path: '/developers',
    title: 'Developer Tools',
    component: DeveloperToolsView,
    isPrivate: true,
    topbar: {
      isDark: false,
      hasLogoutButton: true,
    },
    mobileMenu: false,
  },
  {
    path: '/developers/successful-payment',
    title: 'Developer Tools',
    component: DeveloperToolsView,
    isPrivate: true,
    topbar: {
      isDark: false,
      hasLogoutButton: true,
    },
    mobileMenu: false,
  },
  {
    path: '/developers/failed-payment',
    title: 'Developer Tools',
    component: DeveloperToolsView,
    isPrivate: true,
    topbar: {
      isDark: false,
      hasLogoutButton: true,
    },
    mobileMenu: false,
  },
  {
    path: '/editorganisation-la',
    title: t('Edit Organisation'),
    component: EditOrganisationPage,
    isPrivate: true,
    topbar: { headerBarButton: BackButton },
  },
  {
    path: '/manageusers-la',
    title: t('Manage Users'),
    component: ManageUsersView,
    isPrivate: true,
    topbar: { headerBarButton: BackButton },
    mobileMenu: true,
  },
  {
    path: '/add-new-users',
    title: t('Manage Users'),
    component: CreateUsers,
    isPrivate: true,
    topbar: { headerBarButton: BackButton },
    mobileMenu: true,
  },
  {
    path: '/analytics-la',
    title: t('Analytics'),
    component: AnalyticsPageLa,
    isPrivate: true,
    topbar: { headerBarButton: BackButton },
    mobileMenu: true,
  },
  {
    path: '/analytics-cf',
    title: 'Analytics',
    component: AnalyticsPageCf,
    isPrivate: true,
    topbar: { headerBarButton: BackButton },
    mobileMenu: true,
  },
  {
    path: '/analytics-la-process',
    title: t('Analytics Process'),
    component: ExportProcessPage,
    isPrivate: true,
    topbar: { headerBarButton: BackButton },
    mobileMenu: true,
  },
  {
    path: '/analytics-cf-process',
    title: t('Analytics Process'),
    component: ExportProcessPage,
    isPrivate: true,
    topbar: { headerBarButton: BackButton },
    mobileMenu: true,
  },
  {
    path: '/localised',
    title: 'This is a localised page',
    component: LocalisedView,
    isPrivate: false,
    topbar: { headerBarButton: BackButton },
    featureFlagId: 'useLocalisation',
  },
  {
    path: '/about',
    title: t('About'),
    component: AboutPage,
    isPrivate: true,
    topbar: { headerBarButton: BackButton },
    mobileMenu: true,
  },
  {
    path: '/manageusers/:id',
    title: t('Edit User'),
    component: EditUsers,
    isPrivate: true,
    topbar: { headerBarButton: BackButton },
    mobileMenu: true,
  },
  {
    path: '*',
    component: Redirect,
    props: {
      to: '/error',
    },
    isPrivate: false,
  },
];

export default routes;
