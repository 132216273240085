import cn from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import KdValidationMessage from '../Forms/KdValidationMessage';
import KdDropdownItem from './KdDropdownItem';
import KdDropdownMenu from './KdDropdownMenu';
import KdToggleButton from './KdToggleButton';

export interface DropdownOption {
  key: string;
  label: string;
}

export interface DropdownProps {
  buttonClassName?: string;
  className?: string;
  defaultMenuValue?: string;
  disabled?: boolean;
  error?: any;
  id?: string;
  isArrowHidden?: boolean;
  isReadOnly?: boolean;
  menuClassName?: string;
  onChange?: (key: string) => void;
  options: DropdownOption[];
  placeholder?: string;
  testId?: string;
  value?: string;
  defaultValue?: string;
}

const KdDropdown = React.forwardRef<HTMLDivElement, DropdownProps>(
  (
    {
      buttonClassName,
      className,
      disabled,
      defaultMenuValue,
      error,
      id,
      isArrowHidden,
      isReadOnly,
      menuClassName,
      onChange,
      options,
      placeholder,
      testId,
      value,
      defaultValue,
    },
    ref
  ) => {
    const [show, setShow] = useState(false);
    const [selectedValue, setSelectedValue] = useState(defaultValue || '');
    const menu = useRef(null);
    const button = useRef(null);

    const handleToggle = useCallback(() => {
      setShow(!show);
    }, [show, setShow]);

    const handleSelectValue = useCallback(
      (itemKey: string) => {
        setSelectedValue(itemKey);
        setShow(false);
        if (onChange) onChange(itemKey);
      },
      [setSelectedValue, onChange, setShow]
    );

    const handleClickOutside = useCallback(
      ({ target }: any) => {
        if (
          menu.current &&
          !menu.current.contains(target) &&
          !button.current.contains(target) &&
          show
        ) {
          setShow(false);
        }
      },
      [setShow, show]
    );

    useEffect(() => {
      if (!show) {
        return;
      }

      document.addEventListener('click', handleClickOutside);
      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    }, [handleClickOutside, show]);

    useEffect(() => {
      if (value) setSelectedValue(value);
    }, [value]);

    const foundItemLabel = options?.find(
      (option) => option.key === selectedValue
    )?.label;

    const buttonValue = (selectedValue && foundItemLabel) || placeholder;
    const isDefaultMenuValue = selectedValue === defaultMenuValue;

    return (
      <div
        className={cn('relative', className)}
        data-testid={testId}
        ref={ref}
      >
        <KdToggleButton
          className={buttonClassName}
          disabled={disabled}
          id={id}
          isArrowHidden={isArrowHidden}
          isReadOnly={isReadOnly}
          onClick={handleToggle}
          show={show}
          value={isDefaultMenuValue ? placeholder : buttonValue}
          ref={button}
        />
        {show && (
          <KdDropdownMenu
            ref={menu}
            className={menuClassName}
          >
            {options?.map((option) => (
              <KdDropdownItem
                testid={`${testId}-${option.key}`}
                key={`option${option.key}`}
                onClick={() => handleSelectValue(option.key)}
                isActive={option.key === selectedValue}
                label={option.label}
                isDefaultMenuValue={
                  !selectedValue && option.key === defaultMenuValue
                }
              />
            ))}
          </KdDropdownMenu>
        )}
        {error && <KdValidationMessage error={error} />}
      </div>
    );
  }
);

export default KdDropdown;
