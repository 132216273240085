import { useSelector } from 'react-redux';

import { useAuth } from '../../../hooks/auth/useAuth';
import { RootState } from '../../../store';
import { SchemaFiles, SchemaInfo } from '../../../types/schema';

const schemaFiles: SchemaFiles =
  require(`../../../theme/${process.env.REACT_APP_THEME}/schemaFiles.ts`).default;
const schemaInfo: SchemaInfo = require(`../../../theme/${process.env.REACT_APP_THEME}/schemaInfo.json`);

const getUserTypeUrl = (userType: string) => {
  if (userType === 'CF') {
    return '/#/login';
  }

  return '/#/login-la';
};

const Logo = () => {
  const { userType, isLoggedIn } = useAuth();

  const logoUrl = isLoggedIn ? '/#/dashboard' : getUserTypeUrl(userType);

  return (
    <a href={logoUrl}>
      <img
        alt={schemaInfo.productName}
        className="max-w-[125px] h-[32px]"
        src={schemaFiles.images.primaryLogo}
      />
    </a>
  );
};

export default Logo;
