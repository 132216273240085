import cn from 'classnames';

const sizeClasses = (size: string) => {
  switch (size) {
    case 'normal':
      return 'min-w-[83px] max-w-[83px] min-h-[83px] text-[40px] tracking-[1px]';
    case 'medium':
      return 'min-w-[123px] max-w-[123px] min-h-[123px] text-[50px] tracking-[2px]';
    case 'big':
      return 'min-w-[147px] max-w-[147px]  min-h-[147px] text-[70px] tracking-[5px]';
    case 'small':
      return 'min-w-[50px] max-w-[50px]  min-h-[50px] text-[20px]';
    case 'topbar':
      return 'min-w-[55px] max-w-[55px]  min-h-[55px] text-[26px]';
    default:
      return '';
  }
};
const headerIconBackgroundColor =
  process.env.REACT_APP_THEME === 'ctc' ? '#00FFEC' : '#B5F5E9';

const headerIconTextColor =
  process.env.REACT_APP_THEME === 'ctc' ? '#00B5A8' : '#81B1A8';

const arrayOfBgColors = [
  '#FFA2A2',
  headerIconBackgroundColor,
  '#FFC65C',
  '#7661EA',
  '#A9F7A6',
  '#CCA4F4',
  '#84AFEA',
  '#23AA80',
  '#DEEA84',
  '#EA84A3',
];

const arrayOfTextColor = [
  '#B96B6B',
  headerIconTextColor,
  '#AA843D',
  '#4C3E98',
  '#6D9F6B',
  '#9074AD',
  '#5D7CA7',
  '#1C8162',
  '#9AA25C',
  '#A25B71',
];

const InitialsBox = ({
  userName,
  index,
  size = 'normal',
}: {
  userName: string;
  index: number;
  size?: 'normal' | 'medium' | 'big' | 'small' | 'topbar';
}) => {
  const classes = cn(
    `flex justify-center items-center  rounded-xl`,
    sizeClasses(size)
  );

  const getInitials = (userName: string) => {
    let initials = 'User';
    if (userName && userName.length > 1) {
      const nameSplit = userName?.toUpperCase()?.split(' ');
      const firstName = nameSplit[0] ?? 'X';
      const lastName = nameSplit[1] ?? 'X';
      initials =
        nameSplit.length > 1
          ? `${firstName[0]}${lastName[0]}`
          : `${firstName[0]}${firstName[1]}`;
    }
    return initials;
  };

  const cardColor = arrayOfBgColors[index % arrayOfBgColors.length];
  const cardTextColor = arrayOfTextColor[index % arrayOfTextColor.length];

  return (
    <div
      className={classes}
      style={{
        backgroundColor: cardColor,
        color: cardTextColor,
      }}
    >
      <span
        style={{
          fontWeight: 900,
        }}
      >
        {getInitials(userName)}
      </span>
    </div>
  );
};
export default InitialsBox;
