// eslint-disable-next-line
import { AnyAction, combineReducers } from 'redux';

import { matchToObjectTemplate } from '../functions/utils';
import createBookingStore from './createBookingStore';
import featureFlagStore, { initialFeatureFlagState } from './featureFlagStore';
import manageBookingsStore from './manageBookingsStore';
import manageUserStore from './manageUserStore';
import manageVehicleStore from './manageVehiclesStore';
import viewStore from './viewStore';

const appReducer = combineReducers({
  viewStore,
  manageUserStore,
  manageBookingsStore,
  featureFlagStore,
  manageVehicleStore,
  createBookingStore,
});

// reset the state of a redux store
const rootReducer = (state: any, action: any) => {
  if (action.type === 'FULL_RESET_STORE') {
    return appReducer(undefined, action);
  }
  if (action.type === 'RESET_STORE') {
    const persistedFeatureFlagState = localStorage.getItem('reduxStore')
      ? JSON.parse(localStorage.getItem('reduxStore')).featureFlagStore
      : initialFeatureFlagState;

    const upToDateFeatureFlagState =
      persistedFeatureFlagState || initialFeatureFlagState;

    const upToDateCleanedState = matchToObjectTemplate(
      upToDateFeatureFlagState,
      initialFeatureFlagState
    );

    // eslint-disable-next-line no-param-reassign
    state = {
      featureFlagStore: upToDateCleanedState,
    };
    // eslint-disable-next-line no-console
  }
  return appReducer(state, action);
};
export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
