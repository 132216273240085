import React from 'react';

import ErrorPageView from './pages/ErrorPage/ErrorPageView';

class ErrorBoundary extends React.Component<any, { hasError: false }> {
  constructor(props: Object) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Object, errorInfo: Object) {
    // eslint-disable-next-line no-console
    console.error(`[Error]: ${error}, ${errorInfo}`);
  }

  render() {
    // eslint-disable-next-line react/destructuring-assignment
    if (this.state.hasError) {
      return <ErrorPageView />;
    }

    // eslint-disable-next-line react/destructuring-assignment
    return this.props.children;
  }
}
export default ErrorBoundary;
