import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { callGetUploadStatus } from '../../../api/kerb';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import BulkUploadError from '../../../shared/BulkUpload/BulkUploadError';
import KdButton from '../../../shared/KdButton';
import KdPageTitle from '../../../shared/KdPageTitle';
import ContentLayout from '../../../shared/Layouts/ContentLayout';
import KdPaging from '../../../shared/Paging/KdPaging';
import PrivatePage from '../../../shared/PrivatePage/PrivatePage';
import {
  BulkUploadServerError,
  BulkUploadStatusRequest,
  UploadStatus,
} from '../../../types/bulkUpload';

const BulkUploadErrors = () => {
  const history = useHistory();
  const location = useLocation();
  const correlationId = location.state as string;
  const { t } = useTranslation();
  const { isMobile } = useWindowDimensions();
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalErrors, setTotalErrors] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<BulkUploadServerError[]>([]);
  const [loadMore, setLoadMore] = useState(false);

  const queryErrors = async () => {
    if (correlationId) {
      setIsLoading(true);
      const status: UploadStatus = await callGetUploadStatus({
        correlation_id: correlationId,
        page_number: pageNumber,
        row_status: 'FAILED',
        page_size: 13,
      } as BulkUploadStatusRequest);
      setIsLoading(false);
      setTotalPages(status?.body?.paging?.total_pages ?? 1);
      setTotalErrors(status?.body?.row_counts?.failed ?? 0);
      if (isMobile) {
        setErrors([...errors, ...status?.body?.rows]);
      } else {
        setErrors(status?.body?.rows ?? []);
      }
    }
  };

  useEffect(() => {
    if (loadMore && isMobile && pageNumber < totalPages) {
      setLoadMore(false);
      setPageNumber(pageNumber + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadMore]);

  useEffect(() => {
    queryErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  const handleGoBack = () => {
    history.push('/add-new-users');
  };

  return (
    <PrivatePage noBackground>
      <ContentLayout
        isStickyEffect
        mobileHeaderClassName="bg-dashboardButtonBackground"
        mobileFooterClassName="bg-dashboardButtonBackground"
        desktopBody={
          <>
            <KdPageTitle
              pageTitle={t('Part of your CSV Upload Failed')}
              description={t(
                'Please review and revise before trying to upload again.'
              )}
              actions={
                <KdButton
                  variant="tertiary"
                  onClick={handleGoBack}
                >
                  {t('Go Back')}
                </KdButton>
              }
            />
            <KdPaging
              title={t('Errors (X)').replace('X', totalErrors.toString())}
              className="flex pt-5 text-[20px]"
              data={errors}
              pageNumber={pageNumber}
              totalPages={totalPages}
              setPageNumber={setPageNumber}
              renderItems={(data: BulkUploadServerError[]) => (
                <div className="flex flex-col">
                  <div className="w-full border-b-2 border-secondary py-[10px]">
                    <div className="w-2/12 inline-block max-w-[90px]">
                      {t('Row')}
                    </div>
                    <div className="w-10/12 inline-block">{t('Error')}</div>
                  </div>
                  {data?.map((item: BulkUploadServerError, index: number) => (
                    <BulkUploadError
                      key={`errorRow${index}`}
                      message={item?.status?.message}
                      rowNumber={item?.row_number}
                    />
                  ))}
                </div>
              )}
            />
          </>
        }
        mobileHeader={
          <div className="w-full border-b-2 border-secondary py-[10px]">
            <div className="w-2/12 inline-block max-w-[90px]">{t('Row')}</div>
            <div className="w-10/12 inline-block">{t('Error')}</div>
          </div>
        }
        mobileBody={
          <>
            {errors?.map((item: BulkUploadServerError, index: number) => (
              <BulkUploadError
                key={`errorRow${index}`}
                message={item?.status?.message}
                rowNumber={item?.row_number}
              />
            ))}
          </>
        }
        mobileFooter={
          <KdButton
            variant="tertiary"
            onClick={handleGoBack}
            fullOnMobile
          >
            {t('Go Back')}
          </KdButton>
        }
        onEndOfMobilePage={() => setLoadMore(true)}
        isLoading={isLoading}
      />
    </PrivatePage>
  );
};
export default BulkUploadErrors;
