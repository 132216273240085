import InfoIcon from '@mui/icons-material/InfoOutlined';
import cn from 'classnames';
import React from 'react';

interface Props {
  className?: string;
  error: string;
}

const KdValidationMessage: React.FC<Props> = ({ className, error }) => {
  const classes = cn(
    'bg-warning rounded-b-[5px] text-[14px] text-white leading-[1.66] mx-[5px] px-[5px]',
    className
  );

  return (
    <div className={classes}>
      <InfoIcon className="fill-white text-[15px] mr-[5px] mt-[-2px]" />
      {error}
    </div>
  );
};
export default KdValidationMessage;
