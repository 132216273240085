import { StyledEngineProvider } from '@mui/material';
import { configureStore } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { enableAllPlugins } from 'immer';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import App from './App';
import GlobalStyle from './GlobalStyle';
import Head from './Head';
import {
  matchToObjectTemplate,
  persistStoreToSessionStorage,
} from './functions/utils';
import './i18n';
import './scss/style.scss';
import { initialCreateBookingStoreState as createBookingStore } from './store/createBookingStore';
import { initialFeatureFlagState as featureFlagStore } from './store/featureFlagStore';
import rootReducer from './store/index';
import { initialManageBookingState as manageBookingsStore } from './store/manageBookingsStore';
import { initialManageUserState as manageUserStore } from './store/manageUserStore';
import { initialVehicleState as manageVehicleStore } from './store/manageVehiclesStore';
import { initialViewState as viewStore } from './store/viewStore';

// immer.js: enable all plugins, refer to https://immerjs.github.io/immer/installation
enableAllPlugins();

if (window.location.hostname !== 'localhost') {
  Sentry.init({
    dsn: 'https://9a9ebacf3b5e4c15bfb9c0eda7539072@o1427756.ingest.sentry.io/6781669',

    environment: process.env.NODE_ENV,

    integrations: [new BrowserTracing()],

    tracesSampleRate: 1.0,

    debug: false, // turn on only if sentry is not working

    beforeSend(event) {
      // Do not send sentry events if app is running in development mode (localhost only)

      if (
        event.exception &&
        store.getState().featureFlagStore['errorReporting']
      ) {
        Sentry.showReportDialog({ eventId: event.event_id });
      }

      if (event.user) {
        delete event.user.ip_address;

        delete event.user.email;
      }

      return event;
    },
  });
} else {
  console.info('Sentry is disabled in development mode');
}

const persistedState =
  JSON.parse(localStorage.getItem('reduxStore')) !== null
    ? JSON.parse(localStorage.getItem('reduxStore'))
    : {};

const reduxStoreTemplate = {
  createBookingStore,

  manageBookingsStore,

  manageUserStore,

  manageVehicleStore,

  viewStore,
};

const completePersistedState = matchToObjectTemplate(
  persistedState,

  reduxStoreTemplate
);

const completeFlaggedState = {
  ...completePersistedState,

  featureFlagStore,
};

const store = configureStore({
  reducer: rootReducer,

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),

  preloadedState: completeFlaggedState,
});

export type AppDispatch = typeof store.dispatch;

const container = document.querySelector('.root');

const root = ReactDOM.createRoot(container);

persistStoreToSessionStorage(store);

root.render(
  <>
    <GlobalStyle />
    <StyledEngineProvider injectFirst>
      <Provider store={store}>
        <Head />
        <App />
      </Provider>
    </StyledEngineProvider>
  </>
);

declare global {
  interface Window {
    Cypress: any;

    store: typeof store;
  }
}

if (window.Cypress) {
  window.store = store;
}

export default store;
