import cn from 'classnames';
import { ReactSVG } from 'react-svg';

import { getFileNameColor } from '../../functions/bulkUpload';
import FileUploadIcon from '../../images/File_Upload.svg';

interface Props {
  failedRows: number;
  fileName: string;
  progressState: string;
}

const BulkUploadFileName = ({ failedRows, progressState, fileName }: Props) => {
  const fileColor = getFileNameColor(progressState, failedRows);

  return (
    <div className="flex max-h-[50px]">
      <ReactSVG
        src={FileUploadIcon}
        className={cn(fileColor?.icon, '-m-4 px-1')}
        style={{
          scale: '0.23',
        }}
      />{' '}
      <div
        className={cn(
          'text-[16px] sm:text-[20px] flex items-center -mt-[4px]',
          fileColor?.text
        )}
      >
        {fileName}
      </div>
    </div>
  );
};

export default BulkUploadFileName;
