import Popper from '@mui/material/Popper';
import React, { useCallback, useState } from 'react';

interface Props {
  buttonContent?: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  id?: string;
  popoverTestId?: string;
  testId?: string;
  placement?: 'bottom' | 'right';
}

const KdTooltip: React.FC<Props> = ({
  buttonContent,
  className,
  children,
  id,
  popoverTestId,
  testId,
  placement = 'right',
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [arrowRef, setArrowRef] = useState<null | HTMLElement>(null);

  const handlePopoverOpen = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handlePopoverClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const open = Boolean(anchorEl);
  const popoverId = open ? id : undefined;

  return (
    <>
      <button
        aria-describedby={popoverId}
        className={className}
        data-testid={testId}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        type="button"
      >
        {buttonContent}
      </button>
      <Popper
        anchorEl={anchorEl}
        data-testid={popoverTestId}
        id={popoverId}
        open={open}
        placement={placement}
        className="z-20"
        modifiers={[
          {
            name: 'arrow',
            enabled: true,
            options: {
              element: arrowRef,
            },
          },
        ]}
      >
        <div className="bg-pickerBgColor text-white text-base px-[10px] py-[8px] rounded-md max-w-[260px] mx-[10px] relative">
          {children}
          <span
            className="popover-arrow"
            ref={setArrowRef}
          />
        </div>
      </Popper>
    </>
  );
};
export default KdTooltip;
