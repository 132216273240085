import { t } from 'i18next';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { useBulkUpload } from '../../../hooks/useBulkUploadApi';
import useHandleToast from '../../../hooks/useHandleToast';
import BulkUploadErros from '../../../shared/BulkUpload/BulkUploadErrors';
import BulkUploadFileName from '../../../shared/BulkUpload/BulkUploadFileName';
import BulkUploadLinearProgress from '../../../shared/BulkUpload/BulkUploadLinearProgress';
import BulkUploadProgressTop from '../../../shared/BulkUpload/BulpUploadProgressTop';
import KdButton from '../../../shared/KdButton';
import KdButtonGroup from '../../../shared/KdButtonGroup';
import ContentLayoutCentered from '../../../shared/Layouts/ContentLayoutCentered';
import PrivatePage from '../../../shared/PrivatePage/PrivatePage';
import KdTable from '../../../shared/Table/KdTable';
import { RootState } from '../../../store';
import { BulkUploadServerError } from '../../../types/bulkUpload';
import { BulkUploadProgressStatusEnum } from '../../../types/enums';
import CargoBike from './../../../images/CargoBike.svg';
import VehicleVan from './../../../images/Vehicle_Van.svg';

const motorData = {
  'VRM / Registration': ['1234 567', '8910 111'],
};

const bikeData = {
  identifier: ['1234 567', '8910 111'],
};

const BulkUploadProgress = () => {
  const history = useHistory();
  const location = useLocation();
  const { handleToast } = useHandleToast();

  const {
    startProgressCheck,
    uploadStatus,
    progressState,
    inProgress,
    progress,
    skippedRows,
    failedRows,
  } = useBulkUpload();

  const { fileName, correlationId } =
    (location?.state as {
      fileName?: string;
      correlationId?: string;
    }) ?? {};

  const manageVehicleStore = useSelector(
    (state: RootState) => state.manageVehicleStore
  );
  const { selectedVehicleType } = manageVehicleStore;

  const handleGoBack = () => {
    history.push('/addvehicle');
  };

  const handleViewErrors = () => {
    const firstErrorPage: BulkUploadServerError[] = uploadStatus.body.rows;
    history.push({
      pathname: '/addvehicle/errors',
      state: correlationId,
    });
  };

  useEffect(() => {
    startProgressCheck(correlationId);
  }, []);

  useEffect(() => {
    if (
      (progressState === BulkUploadProgressStatusEnum.COMPLETE_PARTIALLY &&
        failedRows === 0) ||
      progressState === BulkUploadProgressStatusEnum.COMPLETE
    ) {
      setTimeout(() => {
        handleToast({
          variant: 'success',
          content: t('Your CSV upload was successful'),
        });
        history.push('/managevehicles');
      }, 2000);
    }
  }, [uploadStatus, progress, skippedRows, history]);

  const hasError =
    progressState === BulkUploadProgressStatusEnum.STOPPED_DATA ||
    progressState === BulkUploadProgressStatusEnum.STOPPED_SERVER ||
    (progressState === BulkUploadProgressStatusEnum.COMPLETE_PARTIALLY &&
      failedRows > 0);

  return (
    <PrivatePage noBackground>
      <ContentLayoutCentered>
        <BulkUploadProgressTop
          content={(hasError
            ? t('Upload of New [X] Failed')
            : t('Uploading New [X]...')
          ).replace(
            '[X]',
            selectedVehicleType === 'MOTOR_VEHICLE'
              ? t('Motor Vehicles')
              : t('Cargo Bikes')
          )}
          icon={selectedVehicleType === 'CARGO_BIKE' ? CargoBike : VehicleVan}
          inProgress={inProgress}
          isError={hasError}
        />
        <div className="flex flex-col items-center max-w-[750px] w-full text-center">
          <BulkUploadFileName
            failedRows={failedRows}
            fileName={fileName}
            progressState={progressState}
          />
          <BulkUploadLinearProgress
            failedRows={failedRows}
            progressState={progressState}
            value={progress}
          />
          <BulkUploadErros
            progressState={progressState}
            failedRows={failedRows}
            tables={
              selectedVehicleType === 'MOTOR_VEHICLE' ? (
                <KdTable
                  data={motorData}
                  href={
                    'https://kerb-delivery-csv-vehicles-template.s3.eu-west-1.amazonaws.com/Grid+Vehicle+Upload+Template.csv'
                  }
                />
              ) : (
                <KdTable
                  data={bikeData}
                  href={
                    'https://kerb-delivery-csv-vehicles-template.s3.eu-west-1.amazonaws.com/Grid+Bike+Upload+Template.csv'
                  }
                />
              )
            }
          />
          {hasError && (
            <KdButtonGroup
              className="!justify-center"
              onBackClick={handleGoBack}
              renderButtons={
                <>
                  {progressState === 'COMPLETE_PARTIALLY' && failedRows > 0 && (
                    <KdButton
                      onClick={handleViewErrors}
                      fullOnMobile
                    >
                      {t('View Errors')}
                    </KdButton>
                  )}
                </>
              }
            />
          )}
        </div>
      </ContentLayoutCentered>
    </PrivatePage>
  );
};
export default BulkUploadProgress;
