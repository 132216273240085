import InfoIcon from '@mui/icons-material/Info';
import cn from 'classnames';
import { ReactNode } from 'react';

import KdTooltip from '../KdTooltip/KdTooltip';

type Props = {
  text: string;
  isHeader?: boolean;
  rowLabel?: React.ReactNode;
  rules?: ReactNode;
};

const KdTableCell = ({
  text,
  isHeader = false,
  rowLabel = '',
  rules = null,
}: Props) => {
  const classnames = cn(
    'flex relative min-w-[160px] sm:w-[230px] h-[27px] bg-white text-black text-center border-black border-[1px] justify-center items-center',
    isHeader ? 'font-semibold' : 'font-normal'
  );

  return (
    <div className={classnames}>
      {text}
      <div className="absolute right-full top-0">{rowLabel}</div>
      {rules && (
        <KdTooltip
          id={text}
          buttonContent={
            <InfoIcon className="fill-secondary cursor-pointer ml-[5px]" />
          }
          className="absolute bottom-0 right-[3px]"
        >
          {rules}
        </KdTooltip>
      )}
    </div>
  );
};
export default KdTableCell;
