import cn from 'classnames';
import { useEffect, useRef } from 'react';
import { useIntersectionObserver } from 'usehooks-ts';

import LoadingCircle from '../../LoadingCircle';
import { LayoutProps } from '../types/layoutsTypes';

interface Props extends LayoutProps {
  onEndOfListReached: () => void;
  isLoading: boolean;
}

export const MobileContentBody = ({
  children,
  className,
  onEndOfListReached,
  isLoading,
}: Props) => {
  const refEndOfMobileList = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(refEndOfMobileList, {});

  useEffect(() => {
    if (entry?.isIntersecting && !isLoading) {
      if (onEndOfListReached) onEndOfListReached();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entry?.isIntersecting, isLoading]);

  const classes = cn('flex flex-1 flex-col overflow-x-hidden', className);

  return (
    <div className={classes}>
      {children}
      {isLoading && (
        <div
          id="endOfMobileList"
          ref={refEndOfMobileList}
          className="flex h-[120px] w-full justify-center items-center"
        >
          <LoadingCircle />
        </div>
      )}
    </div>
  );
};
