import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';

import { ReactComponent as InfoIcon } from '../../../data/icons/info.svg';
import { ReactComponent as LogoutIcon } from '../../../data/icons/logout.svg';
import { useAuth } from '../../../hooks/auth/useAuth';
import { ReactComponent as DownArrow } from '../../../images/DownArrow.svg';
import { MenuItemsType } from '../types/menuTypes';
import CollapseMenuItem from './CollapseMenuItem';

interface Props {
  menuItems: MenuItemsType;
  onClose: () => void;
  show: boolean;
}

const CollapseMenu = ({ menuItems, onClose, show }: Props) => {
  const { t } = useTranslation();
  const { signOut } = useAuth();

  return (
    <>
      {createPortal(
        <div
          className="fixed bottom-[theme(height.mobileMenu)] left-0 w-screen overflow-clip z-10 bg-accentSecondary rounded-t-xl shadow duration-1000 ease-in-out transition-all"
          style={{ maxHeight: show ? '500px' : '0' }}
          data-testid="bottom-navigation-collapse-menu"
        >
          <button
            className="absolute right-[20px] top-[15px] border-0"
            onClick={onClose}
            type="button"
            data-testid="bottom-navigation-collapse-arrow"
          >
            <DownArrow className="w-[30px]" />
          </button>
          <ul className="py-[30px] px-[60px]">
            {menuItems?.map((link, index) => {
              if (index >= 4) {
                return (
                  <CollapseMenuItem
                    key={`menuItem-${link.testId}`}
                    href={link.linkHref}
                    icon={link.img}
                    title={link.title}
                  />
                );
              }
              return null;
            })}
            <CollapseMenuItem
              href="/help"
              icon={<HelpOutlineIcon />}
              title={t('Need Help?')}
            />
            <CollapseMenuItem
              href="/about"
              icon={<InfoIcon className="w-full" />}
              title={t('About')}
            />
            <CollapseMenuItem
              icon={<LogoutIcon className="w-full" />}
              onClick={() => signOut()}
              title={t('Logout')}
            />
          </ul>
        </div>,
        document.body
      )}
    </>
  );
};

export default CollapseMenu;
