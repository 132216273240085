import useWindowDimensions from '../../hooks/useWindowDimensions';
import HeaderDesktop from './Header/HeaderDesktop';
import HeaderMobile from './Header/HeaderMobile';

type TopBarPropsType = {
  pageTitle: string;
};

const TopBar = ({ pageTitle }: TopBarPropsType): JSX.Element => {
  const { isMobile } = useWindowDimensions();

  return isMobile ? (
    <HeaderMobile pageTitle={pageTitle} />
  ) : (
    <HeaderDesktop pageTitle={pageTitle} />
  );
};

export default TopBar;
