import { t } from 'i18next';
import { ReactSVG } from 'react-svg';

import useWindowDimensions from '../../hooks/useWindowDimensions';
import ErrorIcon from '../../images/ErrorIcon.svg';
import KdIcon from '../KdIcon';

interface Props {
  content: string;
  icon: string;
  inProgress: boolean;
  isError: boolean;
}

const BulkUploadProgressTop = ({
  content,
  icon,
  inProgress,
  isError,
}: Props) => {
  const { isMobile } = useWindowDimensions();

  return (
    <>
      <div className="flex justify-center items-center gap-[30px] mb-[20px]">
        <KdIcon Img={icon} />
      </div>
      <div className="sm:text-[25px] text-[17px] mb-[20px] font-semibold">
        {content}
      </div>
      {inProgress && !isMobile && (
        <div className="text-[#D8D8D8] text-[20px] mb-[20px] w-full text-center leading-7">
          {t(
            'This may take a few minutes. Please do not leave or refresh the page.'
          )}
        </div>
      )}
      {isError && <ReactSVG src={ErrorIcon} />}
    </>
  );
};

export default BulkUploadProgressTop;
