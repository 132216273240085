import { useMemo } from 'react';

import { ReactComponent as HighlightIcon } from '../../../data/icons/light.svg';

interface Props {
  itemWidth: number;
  isMoreButtonActive?: boolean;
  selectedIndex: number;
}

const maxItemsLength = 5;
const svgSize = 27;

const HighlightMobile = ({
  itemWidth,
  isMoreButtonActive,
  selectedIndex,
}: Props) => {
  const left = useMemo(() => {
    const imageOffset = svgSize / 2;

    return isMoreButtonActive
      ? (maxItemsLength - 1) * itemWidth + imageOffset
      : selectedIndex * itemWidth + imageOffset;
  }, [itemWidth, isMoreButtonActive, selectedIndex]);

  return (
    <HighlightIcon
      className="absolute -rotate-90 pointer-events-none transition-all duration-1000"
      width="70"
      height="70"
      style={{
        left,
        top: -4,
      }}
    />
  );
};

export default HighlightMobile;
