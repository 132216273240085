import i18n, { t } from '../../../i18n';
import analyticsHoverImgCtc from '../../../theme/ctc/images/dashboard/analytics.svg';
import bookingsImgCtc from '../../../theme/ctc/images/dashboard/bookings.svg';
import editOrgImgCtc from '../../../theme/ctc/images/dashboard/org.svg';
import manageUsersImgCtc from '../../../theme/ctc/images/dashboard/users.svg';
import manageVehiclesImgCtc from '../../../theme/ctc/images/dashboard/van.svg';
import analyticsHoverImgGrid from '../../../theme/grid/images/dashboard/analytics.svg';
import bookingsImgGrid from '../../../theme/grid/images/dashboard/bookings.svg';
import editOrgImgGrid from '../../../theme/grid/images/dashboard/org.svg';
import manageUsersImgGrid from '../../../theme/grid/images/dashboard/users.svg';
import manageVehiclesImgGrid from '../../../theme/grid/images/dashboard/van.svg';
import { MenuItemsType } from '../types/menuTypes';
import { getMenuDashboardIcon, getMenuIcon } from './getMenuIcon';

const theme = process.env.REACT_APP_THEME;

const menuItemsCF: MenuItemsType = [];

const fill = () => {
  const array: MenuItemsType = [
    {
      title: t('Dashboard'),
      description: '',
      img: getMenuIcon('dashboard'),
      linkHref: '/dashboard',
      highlightLocations: ['dashboard'],
      testId: 'home-button',
      hoverImage: null,
      userType: 'CF',
      visibleOnDashboard: false,
    },
    {
      title: t('Bookings'),
      description: t('Create, remove and amend your bookings'),
      img: getMenuIcon('bookings'),
      imgDashboard: getMenuDashboardIcon('bookings'),
      linkHref: '/bookings',
      highlightLocations: ['bookings', 'createbooking', 'editbooking'],
      testId: 'bookings-button',
      hoverImage: theme === 'grid' ? bookingsImgGrid : bookingsImgCtc,
      userType: 'CF',
    },
    {
      title: t('Manage Fleet'),
      description: t('Make changes to your fleet'),
      img: getMenuIcon('vehicles'),
      imgDashboard: getMenuDashboardIcon('vehicles'),
      linkHref: '/managevehicles',
      highlightLocations: [
        'managevehicles',
        'addvehicle',
        'vehicleupload',
        'edit-delete-vehicle',
      ],
      testId: 'manage-vehicles-button',
      hoverImage:
        theme === 'grid' ? manageVehiclesImgGrid : manageVehiclesImgCtc,
      userType: 'CF',
    },
    {
      title: t('Manage Users'),
      description: t('Manage the users in your organisation and add new users'),
      img: getMenuIcon('users'),
      imgDashboard: getMenuDashboardIcon('users'),
      linkHref: '/manageusers',
      highlightLocations: ['manageusers', 'add-new-users'],
      testId: 'manage-users-button',
      hoverImage: theme === 'grid' ? manageUsersImgGrid : manageUsersImgCtc,
      userType: 'CF',
    },
    {
      title: t('Analytics'),
      description: t('View the analytics for the loading bays in your area'),
      img: getMenuIcon('analytics'),
      imgDashboard: getMenuDashboardIcon('analytics'),
      linkHref: '/analytics-cf',
      highlightLocations: ['analytics-cf'],
      testId: 'analytics-button',
      hoverImage:
        theme === 'grid' ? analyticsHoverImgGrid : analyticsHoverImgCtc,
      userType: 'CF',
    },
    {
      title: t('Edit Organisation'),
      description: '',
      img: getMenuIcon('organisation'),
      linkHref: '/editorganisation',
      highlightLocations: ['editorganisation'],
      testId: 'edit-organisation-button',
      hoverImage: theme === 'grid' ? editOrgImgGrid : editOrgImgCtc,
      userType: 'CF',
      visibleOnDashboard: false,
    },
  ];
  // reset array contents with fresh translated values
  menuItemsCF.length = 0;
  menuItemsCF.push(...array);
};

i18n.on('languageChanged init', () => {
  fill();
});

export default menuItemsCF;
