import { t } from 'i18next';

interface Props {
  rowNumber: number;
  message: string;
}

const BulkUploadError = ({ message, rowNumber }: Props) => (
  <div className="w-full py-[10px] border-b-2 border-secondary">
    <div className="w-2/12 inline-block max-w-[90px] 300 align-top">
      {rowNumber}
    </div>
    <div className="w-10/12 inline-block">{message || t('Unknown Error')}</div>
  </div>
);

export default BulkUploadError;
