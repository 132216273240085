import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { KdButtonType } from '../types/KdButton';
import LoadingCircle from './LoadingCircle';

/**
 * @param {String} type used to determine type of button
 * @param {boolean} disabled used to determine if button is enabled
 * @param {String} className the class name of the button
 * @param {boolean} apiCalling is true/false depending if the call to the api has came back
 * @param {Function} clickFunction passes a function that will be run on click of the button
 * @returns {Component} returns custom button used to call an API
 */

const variants = {
  primary:
    'bg-primary text-accentSecondary hover:bg-buttonPrimaryHover focus:bg-buttonPrimaryHover',
  secondary:
    'bg-accentSecondaryDark hover:bg-buttonSecondaryHover focus:bg-buttonSecondaryHover',
  tertiary:
    'bg-accentTertiary hover:bg-buttonTertiaryHover focus:bg-buttonTertiaryHover',
  warning: 'bg-warning',
  success: 'bg-success',
  dashboard:
    'sm:bg-accentSecondaryDark flex flex-col sm:flex-row h-13 w-70 py-2 px-0 sm:px-3 mt-[30px] sm:mt-[40px] items-center text-[27px] hover:sm:bg-dashboardButtonBackgroundHover sm:hover:text-dashboardButtonTextHover hover:scale-[1.05] hover:translate-x-[5px] focus:sm:bg-dashboardButtonBackgroundHover focus:sm:text-dashboardButtonTextHover focus:scale-[1.05] focus:translate-x-[5px]',
};

const sizes = {
  default: 'text-[16px] sm:text-[18px]',
  small: 'text-[14px] min-h-[36px] min-w-[180px]',
  large: 'sm:text-[20px]',
  tiny: 'text-[14px] min-w-[130px] w-auto shrink-1',
  extraSmall: 'text-[14px] min-w-[70px] !max-w-[70px] min-h-[36px]',
};

const KdButton = React.forwardRef<HTMLButtonElement, KdButtonType>(
  (props, ref) => {
    const {
      onClick,
      onKeyPress,
      onKeyDown,
      onMouseEnter,
      onMouseLeave,
      className,
      apiCalling,
      disabled,
      children = 'OK',
      type = 'button',
      testId,
      tabIndex,
      fullOnMobile,
      size = 'default',
      styles = {},
      variant = 'primary',
      withIcon,
    } = props;
    const { t } = useTranslation();

    const cursorClass = 'cursor-pointer disabled:cursor-not-allowed';
    const classes = cn(
      'btn',
      {
        [`${variants[variant]}`]: variant,
        [`${sizes[size]}`]: size,
        'inline-flex items-center justify-center': withIcon,
        'w-full sm:w-auto': fullOnMobile,
      },
      apiCalling ? 'cursor-wait' : cursorClass,
      className
    );

    return (
      <button
        // eslint-disable-next-line react/button-has-type
        type={type}
        onClick={(ev) => {
          if (onClick && !apiCalling) {
            onClick(ev);
          }
        }}
        onKeyPress={(ev) => {
          if (onKeyPress) {
            onKeyPress(ev);
          }
        }}
        onKeyDown={(ev) => {
          if (onKeyDown) {
            onKeyDown(ev);
          }
        }}
        onMouseEnter={(ev) => {
          if (onMouseEnter) {
            onMouseEnter(ev);
          }
        }}
        onMouseLeave={(ev) => {
          if (onMouseLeave) {
            onMouseLeave(ev);
          }
        }}
        data-testid={testId}
        style={styles}
        className={classes}
        disabled={disabled || apiCalling}
        tabIndex={tabIndex}
        ref={ref}
      >
        {!apiCalling ? children ?? t('OK') : <LoadingCircle />}
      </button>
    );
  }
);

export default KdButton;
