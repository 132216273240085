/* eslint-disable camelcase */
import { AwsInstance } from '../hooks/auth/awsManager';
import { BookingsResponse } from '../types/bookings';
import {
  BulkUploadRequest,
  BulkUploadResponse,
  BulkUploadStatusRequest,
  BulkUploadStatusResponse,
  UploadStatus,
} from '../types/bulkUpload';
import { FuelTypesEnum, VehicleTypesEnum } from '../types/enums';
import { VehicleTypes } from '../types/vehicleTypes';

const apiUrl = process.env.REACT_APP_REST_URL;

export const callGetUser = (userId: string) => {
  return fetch(`${apiUrl}/users/${userId}`, {
    method: 'GET',
    headers: {
      Authorization: AwsInstance.getInstance().getToken(),
      'Content-Type': 'application/json',
    },
  });
};

export const callCreateRegistration = (data: Object) => {
  return fetch(`${apiUrl}/registration`, {
    method: 'POST',
    headers: {
      Authorization: AwsInstance.getInstance().getToken(),
      'Content-Type': 'application/json',
    },

    body: JSON.stringify({
      ...data,
    }),
  });
};

export const callGetUsersByGroup = (userGroup?: string) => {
  const queryString = userGroup === undefined ? '' : `?user_group=${userGroup}`;
  return fetch(`${apiUrl}/users${queryString}`, {
    method: 'GET',
    headers: {
      Authorization: AwsInstance.getInstance().getToken(),
      'Content-Type': 'application/json',
    },
  });
};

export const callAddUser = ({
  fullName,
  userType,
  temporaryPassword,
  email,
  number,
}: {
  fullName: string;
  userType: string;
  temporaryPassword: string;
  email?: string;
  number?: string;
}) => {
  return fetch(`${apiUrl}/users`, {
    method: 'POST',
    headers: {
      Authorization: AwsInstance.getInstance().getToken(),
      'Content-Type': 'application/json',
    },

    body: JSON.stringify({
      user_name: fullName,
      user_group: userType,
      temporary_password: temporaryPassword,
      email: email?.toLowerCase(),
      phone: number,
    }),
  });
};

export const callPatchUser = (user_id: string, userName: string) => {
  return fetch(`${apiUrl}/users/${user_id}`, {
    method: 'PATCH',
    headers: {
      Authorization: AwsInstance.getInstance().getToken(),
      'Content-Type': 'application/json',
    },

    body: JSON.stringify({
      name: userName,
    }),
  });
};

export const callPatchOrganisation = (
  organisation_id: string,
  patchParams: Object
) => {
  return fetch(`${apiUrl}/organisations/${organisation_id}`, {
    method: 'PATCH',
    headers: {
      Authorization: AwsInstance.getInstance().getToken(),
      'Content-Type': 'application/json',
    },

    body: JSON.stringify(patchParams),
  });
};

export const callDeleteUser = (user_id: string) => {
  return fetch(`${apiUrl}/users/${user_id}`, {
    method: 'DELETE',
    headers: {
      Authorization: AwsInstance.getInstance().getToken(),
      'Content-Type': 'application/json',
    },
  });
};
export const callCreateHelpTicket = (
  messageText: string,
  addressee: string,
  subject: string
) => {
  return fetch(`${apiUrl}/support`, {
    method: 'POST',
    headers: {
      Authorization: AwsInstance.getInstance().getToken(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      subject: subject,
      message: messageText,
      support_addressee: addressee,
    }),
  });
};

export const callGetAllBookings = (date: string) => {
  return fetch(`${apiUrl}/paging/bookings?date=${date}`, {
    method: 'GET',
    headers: {
      Authorization: AwsInstance.getInstance().getToken(),
      'Content-Type': 'application/json',
    },
  });
};

export const callGetPagedBookings = async (
  date: string,
  page_number: number
): Promise<BookingsResponse> => {
  if (!date) {
    throw new Error('Date is required');
  }

  const response = await fetch(
    `${apiUrl}/paging/bookings?date=${date}&limit=300&page_number=${page_number}`,
    {
      method: 'GET',
      headers: {
        Authorization: AwsInstance.getInstance().getToken(),
        'Content-Type': 'application/json',
      },
    }
  );

  if (!response.ok) {
    const error: Error = await response.json();
    throw new Error(error?.message);
  }

  return await response.json();
};

export const callDeleteBooking = (bookingId: string) => {
  return fetch(`${apiUrl}/bookings/${bookingId}`, {
    method: 'DELETE',
    headers: {
      Authorization: AwsInstance.getInstance().getToken(),
      'Content-Type': 'application/json',
    },
  });
};

export const callGetAllVehicles = () => {
  return fetch(`${apiUrl}/vehicles`, {
    method: 'GET',
    headers: {
      Authorization: AwsInstance.getInstance().getToken(),
      'Content-Type': 'application/json',
    },
  });
};

export const callPatchBooking = (booking_id: string, patchParams: Object) => {
  return fetch(`${apiUrl}/bookings/${booking_id}`, {
    method: 'PATCH',
    headers: {
      Authorization: AwsInstance.getInstance().getToken(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(patchParams),
  });
};

export const callDeleteVehicle = (vehicleId: string) => {
  return fetch(`${apiUrl}/vehicles/${vehicleId}`, {
    method: 'DELETE',
    headers: {
      Authorization: AwsInstance.getInstance().getToken(),
      'Content-Type': 'application/json',
    },
  });
};

export const callCreateVehicle = (vrm: string, vehicleType?: VehicleTypes) => {
  return fetch(`${apiUrl}/vehicles/`, {
    method: 'POST',
    headers: {
      Authorization: AwsInstance.getInstance().getToken(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ vrm, vehicle_type: vehicleType }),
  });
};

export const callGetUploadUrl = async (
  request: BulkUploadRequest
): Promise<BulkUploadResponse> => {
  const { entity_type } = request;
  if (!entity_type) {
    throw new Error('entity_type is required');
  }
  const response: Response = await fetch(`${apiUrl}/uploads/`, {
    method: 'POST',
    headers: {
      Authorization: AwsInstance.getInstance().getToken(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(request),
  });

  if (!response.ok) {
    const error: Error = await response.json();
    throw new Error(error.message);
  }
  return (await response.json()) as BulkUploadResponse;
};

export const UploadToS3 = async (
  file: File,
  url: string,
  fields: { [key: string]: string }
): Promise<Response> => {
  const formData = new FormData();

  /* TODO: keep it for debugging purposes
  console.log("fields['AWSAccessKeyId']:", fields['AWSAccessKeyId'])
  console.log("fields['key']:", fields['key'])
  console.log("fields['policy']:", fields['policy'])
  console.log("fields['signature']:", fields['signature'])
  console.log("fields['x-amz-security-token']:", fields['x-amz-security-token'])
  */

  formData.append('AWSAccessKeyId', fields['AWSAccessKeyId']);
  formData.append('key', fields['key']);
  formData.append('policy', fields['policy']);
  formData.append('signature', fields['signature']);
  formData.append('x-amz-security-token', fields['x-amz-security-token']);
  formData.append('file', file);

  return fetch(url, {
    method: 'POST',
    body: formData,
  });
};

export const callGetUploadStatus = async (
  request: BulkUploadStatusRequest
): Promise<UploadStatus> => {
  const { correlation_id, page_size, page_number, row_status } = request;
  if (!correlation_id) {
    throw new Error('The correlation_id is not provided');
  }
  const response: Response = await fetch(
    `${apiUrl}/uploads/status/${correlation_id}${
      page_size ? `?page_size=${page_size}` : ''
    }${page_number ? `&page_number=${page_number}` : ''}${
      row_status ? `&row_status=${row_status}` : ''
    }${row_status ? `&row_status=${row_status}` : ''}`,
    {
      method: 'GET',
      headers: {
        Authorization: AwsInstance.getInstance().getToken(),
        'Content-Type': 'application/json',
      },
    }
  );
  if (!response.ok) {
    const error: Error = await response.json();
    throw new Error(error.message);
  }
  const body = (await response.json()) as BulkUploadStatusResponse;
  return {
    httpStatus: response.status,
    body: body,
  } as UploadStatus;
};

export const callPatchVehicle = ({
  vehicleID,
  patchParams,
}: {
  vehicleID: string;
  patchParams: Object;
}) => {
  return fetch(`${apiUrl}/vehicles/${vehicleID}`, {
    method: 'PATCH',
    headers: {
      Authorization: AwsInstance.getInstance().getToken(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(patchParams),
  });
};

export const callGetDrivers = () => {
  return fetch(`${apiUrl}/users?user_group=Driver`, {
    method: 'GET',
    headers: {
      Authorization: AwsInstance.getInstance().getToken(),
      'Content-Type': 'application/json',
    },
  });
};

export const callGetPagedVehicles = (params: {
  numberOfVehicles: number;
  pageNumber: number;
  vehicleType?: VehicleTypesEnum;
  fuelType?: FuelTypesEnum;
  searchValue?: string;
}) => {
  const { numberOfVehicles, pageNumber, vehicleType, fuelType, searchValue } =
    params;

  const vehicle = vehicleType || VehicleTypesEnum.ALL;
  const searchParams = searchValue
    ? `&vrm=${searchValue}&is_partial_search=true`
    : '';
  const fuelParams = fuelType ? `&fuel_type=${fuelType}` : '';

  return fetch(
    `${apiUrl}/paging/vehicles?limit=${numberOfVehicles}&page_number=${pageNumber}&vehicle_type=${vehicle}${searchParams}${fuelParams}`,
    {
      method: 'GET',
      headers: {
        Authorization: AwsInstance.getInstance().getToken(),
        'Content-Type': 'application/json',
      },
    }
  );
};

export const callCreateBooking = (
  start: string,
  end: string,
  vehicleID: string,
  bayID: string,
  driverID: string
) => {
  return fetch(`${apiUrl}/bookings/`, {
    method: 'POST',
    headers: {
      Authorization: AwsInstance.getInstance().getToken(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      start,
      end,
      vehicle_id: vehicleID,
      bay_id: bayID,
      driver_id: driverID,
    }),
  });
};

export const callGetVehicleByID = (id: string) => {
  return fetch(`${apiUrl}/vehicles/${id}`, {
    method: 'GET',
    headers: {
      Authorization: AwsInstance.getInstance().getToken(),
      'Content-Type': 'application/json',
    },
  });
};

export const callGetPagedVehiclesByVRM = (
  numberOfVehicles: number,
  pageNumber: number,
  vrm: string
) => {
  return fetch(
    `${apiUrl}/paging/vehicles?limit=${numberOfVehicles}&page_number=${pageNumber}&vrm=${vrm}&is_partial_search=true`,
    {
      method: 'GET',
      headers: {
        Authorization: AwsInstance.getInstance().getToken(),
        'Content-Type': 'application/json',
      },
    }
  );
};
export const callCheckMinVersion = () => {
  return fetch(`${apiUrl}/versions`, {
    method: 'GET',
    headers: {
      Authorization: AwsInstance.getInstance().getToken(),
      'Content-Type': 'application/json',
    },
  });
};

export const callGetSectors = () => {
  return fetch(`${apiUrl}/sectors`, {
    method: 'GET',
    headers: {
      Authorization: AwsInstance.getInstance().getToken(),
      'Content-Type': 'application/json',
    },
  });
};

export const callGetCompaniesWithBookings = (
  startDate: string,
  endDate: string
) => {
  return fetch(
    `${apiUrl}/reporting/companies_with_bookings?start=${startDate}&end=${endDate}`,
    {
      method: 'GET',
      headers: {
        Authorization: AwsInstance.getInstance().getToken(),
        'Content-Type': 'application/json',
      },
    }
  );
};

export const callGetRogueVehiclesLA = (startDate: string, endDate: string) => {
  return fetch(
    `${apiUrl}/reporting/rogue_hotspots?start=${startDate}&end=${endDate}`,
    {
      method: 'GET',
      headers: {
        Authorization: AwsInstance.getInstance().getToken(),
        'Content-Type': 'application/json',
      },
    }
  );
};

export const callGetAverageBookingDuration = (
  startDate: string,
  endDate: string
) => {
  return fetch(
    `${apiUrl}/reporting/average_booking_time?start=${startDate}&end=${endDate}`,
    {
      method: 'GET',
      headers: {
        Authorization: AwsInstance.getInstance().getToken(),
        'Content-Type': 'application/json',
      },
    }
  );
};

export const callGetBookingByTime = (startDate: string, endDate: string) => {
  return fetch(
    `${apiUrl}/reporting/bookings_by_time?start=${startDate}&end=${endDate}`,
    {
      method: 'GET',
      headers: {
        Authorization: AwsInstance.getInstance().getToken(),
        'Content-Type': 'application/json',
      },
    }
  );
};

export const callGetRogueVehicleByTime = (
  startDate: string,
  endDate: string
) => {
  return fetch(
    `${apiUrl}/reporting/rogue_by_time?start=${startDate}&end=${endDate}`,
    {
      method: 'GET',
      headers: {
        Authorization: AwsInstance.getInstance().getToken(),
        'Content-Type': 'application/json',
      },
    }
  );
};

export const callGetBookingsByDuration = (
  startDate: string,
  endDate: string
) => {
  return fetch(
    `${apiUrl}/reporting/bookings_by_duration?start=${startDate}&end=${endDate}`,
    {
      method: 'GET',
      headers: {
        Authorization: AwsInstance.getInstance().getToken(),
        'Content-Type': 'application/json',
      },
    }
  );
};

export const callGetBookingsByDriver = (startDate: string, endDate: string) => {
  return fetch(
    `${apiUrl}/reporting/bookings_by_driver?start=${startDate}&end=${endDate}`,
    {
      method: 'GET',
      headers: {
        Authorization: AwsInstance.getInstance().getToken(),
        'Content-Type': 'application/json',
      },
    }
  );
};

export const callGetBookingsByVrmBay = (startDate: string, endDate: string) => {
  return fetch(
    `${apiUrl}/reporting/bookings_by_vrm_bay?start=${startDate}&end=${endDate}`,
    {
      method: 'GET',
      headers: {
        Authorization: AwsInstance.getInstance().getToken(),
        'Content-Type': 'application/json',
      },
    }
  );
};

export const callGetBookingsByVehicle = (
  startDate: string,
  endDate: string
) => {
  return fetch(
    `${apiUrl}/reporting/bookings_by_vrm?start=${startDate}&end=${endDate}`,
    {
      method: 'GET',
      headers: {
        Authorization: AwsInstance.getInstance().getToken(),
        'Content-Type': 'application/json',
      },
    }
  );
};

export const callGetBaysForArea = (
  lat: number,
  lon: number,
  radius: number,
  limit: number,
  page: number
) => {
  return fetch(
    `${apiUrl}/paging/bays?lat=${lat}&lon=${lon}&radius=${radius}&limit=${limit}&page_number=${page}`,
    {
      method: 'GET',
      headers: {
        Authorization: AwsInstance.getInstance().getToken(),
        'Content-Type': 'application/json',
      },
    }
  );
};

export const callGetBayAvailability = (bayId: string, date: string) => {
  return fetch(`${apiUrl}/bays/${bayId}/availability?date=${date}`, {
    method: 'GET',
    headers: {
      Authorization: AwsInstance.getInstance().getToken(),
      'Content-Type': 'application/json',
    },
  });
};

export const callGetBayByID = (bayId: string) => {
  return fetch(`${apiUrl}/bays/${bayId}`, {
    method: 'GET',
    headers: {
      Authorization: AwsInstance.getInstance().getToken(),
      'Content-Type': 'application/json',
    },
  });
};

export const callBayUsage = (startDate: string, endDate: string) => {
  return fetch(
    `${apiUrl}/reporting/bay_usage?start=${startDate}&end=${endDate}`,
    {
      method: 'GET',
      headers: {
        Authorization: AwsInstance.getInstance().getToken(),
        'Content-Type': 'application/json',
      },
    }
  );
};

export const callGetBayHoursOfOperation = (date: string, bayId: string) => {
  return fetch(`${apiUrl}/bays/${bayId}/hours?local_date=${date}`, {
    method: 'GET',
    headers: {
      Authorization: AwsInstance.getInstance().getToken(),
      'Content-Type': 'application/json',
    },
  });
};
