import { vehicleType } from '../types/vehicle';
import { VehicleTypes } from '../types/vehicleTypes';

type manageVehicleStoreType = {
  leftPanelState: string;
  selectedVehicleData: vehicleType | null;
  vehicleDataOutOfSync: boolean;
  selectedVehicleType: VehicleTypes;
  addedVehicleType: VehicleTypes;
};
export type manageVehicleStoreActionType =
  | {
      type: 'SET_VEHICLE_DATA_OUT_OF_SYNC';
      payload: boolean;
    }
  | {
      type: 'SET_LEFT_PANEL_STATE';
      payload: string;
    }
  | {
      type: 'SET_SELECTED_VEHICLE_DATA';
      payload: vehicleType | null;
    }
  | {
      type: 'SET_SELECTED_VEHICLE_TYPE';
      payload: VehicleTypes;
    }
  | {
      type: 'SET_ADDED_VEHICLE_TYPE';
      payload: VehicleTypes;
    };

export const initialVehicleState: manageVehicleStoreType = {
  vehicleDataOutOfSync: false,
  leftPanelState: 'NoSelectedVehicle',
  selectedVehicleData: null,
  selectedVehicleType: 'MOTOR_VEHICLE',
  addedVehicleType: 'MOTOR_VEHICLE',
};

const manageVehicleStore = (
  state = initialVehicleState,
  action: manageVehicleStoreActionType
) => {
  switch (action.type) {
    case 'SET_LEFT_PANEL_STATE':
      return {
        ...state,
        leftPanelState: action.payload,
      };
    case 'SET_SELECTED_VEHICLE_DATA':
      return {
        ...state,
        selectedVehicleData: action.payload,
      };
    case 'SET_VEHICLE_DATA_OUT_OF_SYNC':
      return {
        ...state,
        vehicleDataOutOfSync: action.payload,
      };
    case 'SET_SELECTED_VEHICLE_TYPE':
      return {
        ...state,
        selectedVehicleType: action.payload,
      };
    case 'SET_ADDED_VEHICLE_TYPE':
      return {
        ...state,
        addedVehicleType: action.payload,
      };
    default:
      return state;
  }
};

export default manageVehicleStore;
