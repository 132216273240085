import { t } from 'i18next';
import { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';

import { useAuth } from '../hooks/auth/useAuth';
import useHandleToast from '../hooks/useHandleToast';
import LoadingCircle from '../shared/LoadingCircle';
import { PrivateRouteType } from '../types/routes';

const PrivateRoute = ({ children, exact, path }: PrivateRouteType) => {
  const {
    isLoggedIn,
    isUserRestoreComplete,
    assertUserLogin,
    assertLoggedInUserType,
  } = useAuth();
  const { handleToast } = useHandleToast();

  useEffect(() => {
    const securityCheck = async () => {
      await assertUserLogin();
      if (isUserRestoreComplete) {
        await assertLoggedInUserType().catch((error) => {
          handleToast({
            variant: 'error',
            content: error?.message,
            noTopBar: true,
          });
        });
      }
    };
    securityCheck();
  }, []);

  return isLoggedIn ? (
    <Route
      exact={exact}
      path={path}
      render={
        ({ location }) => {
          return isLoggedIn ? (
            <>{children}</>
          ) : (
            <Redirect
              to={{
                pathname: '/',
                state: { from: location },
              }}
            />
          );
        }

        // eslint-disable-next-line react/jsx-curly-newline
      }
    />
  ) : (
    <LoadingCircle text={t('Loading User Information...')} />
  );
};
export default PrivateRoute;
