import { format } from 'date-fns';

import BayInfo from '../types/bayInfo';
import { driverType } from '../types/driverType';
import { vehicleType } from '../types/vehicle';
import { t } from './../i18n';

export type createBookingStoreActionTypes =
  | {
      type: 'SET_MOBILE_BAY_INFO_MENU_OPEN';
      payload: boolean;
    }
  | {
      type: 'SET_IS_IN_AMEND_MODE';
      payload: boolean;
    }
  | {
      type: 'SET_SELECTED_BAY';
      payload: BayInfo;
    }
  | {
      type: 'SET_LEFT_PANEL_STATUS';
      payload: 'nobay' | 'bayselected' | 'newbooking';
    }
  | {
      type: 'SET_CREATE_BOOKING_TIME';
      payload: string;
    }
  | {
      type: 'SET_SELECTED_DRIVER';
      payload: driverType;
    }
  | {
      type: 'SET_BOOKING_DATE';
      payload: string;
    }
  | {
      type: 'SET_DATA_RELOADING';
      payload: boolean;
    }
  | {
      type: 'SET_BOOKING_VRM';
      payload: vehicleType;
    }
  | {
      type: 'SET_CREATE_BOOKING_MODE';
      payload: 'create' | 'amend';
    };

type createBookingStoreType = {
  selectedBay: BayInfo;
  leftPanelStatus: 'nobay' | 'bayselected' | 'newbooking';
  createBookingTime: string;
  selectedDriver: driverType;
  bookingDate: string;
  dataReloading: boolean;
  bookingVRM: vehicleType;
  createBookingMode: 'create' | 'amend';
  isInAmendMode: boolean;
  mobileBayInfoMenuOpen: boolean;
};

const initialState: createBookingStoreType = {
  selectedBay: undefined,
  leftPanelStatus: 'nobay',
  createBookingTime: t('Select a Booking Time'),
  selectedDriver: null,
  bookingDate: `${format(new Date(), 'yyyy-MM-dd')}`,
  dataReloading: false,
  bookingVRM: undefined,
  createBookingMode: 'create',
  isInAmendMode: true,
  mobileBayInfoMenuOpen: false,
};

export const initialCreateBookingStoreState: createBookingStoreType = {
  leftPanelStatus: 'nobay',
  selectedBay: undefined,
  createBookingTime: t('Select a Booking Time'),
  selectedDriver: null,
  bookingDate: `${format(new Date(), 'yyyy-MM-dd')}`,
  dataReloading: false,
  bookingVRM: undefined,
  createBookingMode: 'create',
  isInAmendMode: true,
  mobileBayInfoMenuOpen: false,
};

const createBookingStore = (
  state = initialState,
  action: createBookingStoreActionTypes
) => {
  switch (action.type) {
    case 'SET_SELECTED_BAY':
      return {
        ...state,
        selectedBay: action.payload,
      };
    case 'SET_LEFT_PANEL_STATUS':
      return {
        ...state,
        leftPanelStatus: action.payload,
      };
    case 'SET_CREATE_BOOKING_TIME':
      return {
        ...state,
        createBookingTime: action.payload,
      };
    case 'SET_SELECTED_DRIVER':
      return {
        ...state,
        selectedDriver: action.payload,
      };
    case 'SET_BOOKING_DATE':
      return {
        ...state,
        bookingDate: action.payload,
      };
    case 'SET_DATA_RELOADING':
      return {
        ...state,
        dataReloading: action.payload,
      };
    case 'SET_BOOKING_VRM':
      return {
        ...state,
        bookingVRM: action.payload,
      };
    case 'SET_CREATE_BOOKING_MODE':
      return {
        ...state,
        createBookingMode: action.payload,
      };
    case 'SET_IS_IN_AMEND_MODE':
      return {
        ...state,
        isInAmendMode: action.payload,
      };
    case 'SET_MOBILE_BAY_INFO_MENU_OPEN':
      return {
        ...state,
        mobileBayInfoMenuOpen: action.payload,
      };
    default:
      return state;
  }
};
export default createBookingStore;
