import { useHistory } from 'react-router-dom';

export type UseRedirect = {
  redirectTo: (path: string) => void;
  goBack: () => void;
  goForward: () => void;
  history: any;
  pathname: string;
};

/**
 * A Custom Hook that can intercept all redirects
 */
export const useRedirect = (): UseRedirect => {
  const history = useHistory();

  const redirectTo = (path: string) => {
    const currentPath = history?.location?.pathname ?? '';
    if (currentPath !== path) {
      console.info(`Redirecting ${currentPath} to ${path}`);
      history?.push(path);
    }
  };

  const goForward = () => {
    history.goForward();
  };
  const goBack = () => {
    history.goBack();
  };

  return {
    redirectTo,
    goBack,
    goForward,
    history,
    pathname: history?.location?.pathname,
  };
};
