import ClearIcon from '@mui/icons-material/Clear';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import cn from 'classnames';
import React from 'react';

export interface ModalProps {
  className?: string;
  children?: React.ReactNode;
  isCloseButton?: boolean;
  onClose?: () => void;
  show: boolean;
  testId?: string;
  variant?: 'light' | 'dark';
  hasPadding?: boolean;
  withTopBar?: boolean;
}

const modalDarkStyles = `bg-modalDarkBg 
  w-full 
  h-[calc(100%-60px)]
  p-[30px]
  xl:w-[950px] 
  xl:py-[90px] 
  sm:py-[60px] 
  sm:rounded-[16px]
  sm:!h-auto
  sm:top-[50%]
  sm:-translate-y-2/4  
`;

const modalLight = `bg-white 
  text-accentSecondary 
  rounded-[16px]
  p-[20px]
  w-[calc(100%-40px)]
  left-[20px] 
  top-[50%] 
  -translate-y-2/4
  xl:w-[870px]
  xl:px-[100px]
  sm:p-[50px]
`;

const KdModal: React.FC<ModalProps> = ({
  children,
  className,
  isCloseButton,
  onClose,
  show,
  testId,
  variant = 'light',
  hasPadding = true,
  withTopBar = true,
}) => {
  const classes = cn('overflow-y-auto', {
    'sm:!left-[theme(width.menu)] sm:!top-[theme(height.header)]': withTopBar,
  });

  const modalDark = cn(modalDarkStyles, {
    'xl:px-[120px] sm:px-[90px] ': hasPadding,
    '!h-full': !withTopBar,
  });

  const contentClasses = cn(
    'absolute sm:left-[50%] sm:-translate-x-2/4 sm:w-[660px] kd-modal',
    variant === 'dark' ? modalDark : modalLight,
    className
  );

  return (
    <Modal
      componentsProps={{
        backdrop: { className: 'bg-modalBackdrop backdrop-blur' },
      }}
      components={{ Backdrop: Backdrop }}
      className={classes}
      onClose={onClose}
      open={show}
      data-testid={testId}
    >
      <Box
        className={contentClasses}
        data-testid={'kd-modal-box'}
      >
        {isCloseButton && (
          <button
            className="absolute right-[20px] top-[20px] p-0 bg-transparent hover:bg-transparent text-white"
            onClick={onClose}
            type="button"
          >
            <ClearIcon />
          </button>
        )}
        {children}
      </Box>
    </Modal>
  );
};

export default KdModal;
